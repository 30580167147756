import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserPermissions } from "redux/slices/auth/auth.action";
import { AppDispatch, RootState } from "redux/store";

const useValidatePermission = () => {
  const { permissions } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!permissions?.length) {
      dispatch(getUserPermissions());
    }
  }, []);

  const checkPermission = (permission: string) => {
    return permissions?.includes(permission);
  };

  return { checkPermission };
};

export default useValidatePermission;
