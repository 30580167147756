import { useState, useEffect } from "react";
import { days } from "utils/common";

const useDaySelector = (checkedDays = [], onSelect) => {
  const [selectedDay, setSelectedDay] = useState<string[]>(checkedDays);

  const handleDayClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    setSelectedDay((prevSelectedDays) => {
      if (checked) {
        return [...prevSelectedDays, value];
      } else {
        return prevSelectedDays.filter((day) => day !== value);
      }
    });
  };

  useEffect(() => {
    onSelect(selectedDay.join());
  }, [selectedDay]);

  const isDayInRange = (day) => {};

  return {
    days,
    selectedDay,
    setSelectedDay,
    handleDayClick,
  };
};
export default useDaySelector;
