import { Grid } from "@mui/material";
import { gutterSpace } from "appConstants/styles";

type NoDataMessagePropType = {
  messageContent?: string;
};

const NoDataMessage = ({ messageContent }: NoDataMessagePropType) => {
  return (
    <Grid container spacing={gutterSpace}>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        xs={12}
        item
        sx={{ height: "100vh" }}
      >
        {messageContent ??
          "Oops! Looks like the data you are trying to view does not exist!"}
      </Grid>
    </Grid>
  );
};

export default NoDataMessage;
