import axios from "axios";
import { BASE_URL } from "./urls";
import { LOCAL_STORAGE_KEYS } from "../appConstants";

const axiosClient = axios.create({
  baseURL: BASE_URL,
});

axiosClient.interceptors.request.use(async (config) => {
  const token: string = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
  // add token in headers if token is present
  if (token && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    //if response header contains a custom field
    if (response.headers["x-custom-field"]) {
      //do something with the custom field value
    }
    return response;
  },
  function (error) {
    //if error response status is 401-unauthorized
    if (error.response.status === 401) {
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
