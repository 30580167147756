import { forwardRef } from "react";
import {
  Box,
  ClickAwayListener,
  InputBase,
  Paper,
  Popper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/system";
import { searchBar } from "appConstants/styles";
import { Button } from "components";
import useSearchBar from "./useSearchBar";
import { SearchBarRef } from "types/finance";

type SearchBarPropType = {
  searchParam?: string;
  showSearchIcon?: boolean;
  showFilterListIcon?: boolean;
  showBorderBottom?: boolean;
  showSearchResultsPopper?: boolean;
  placeholder?: string;
  sx?: object;
  children?: React.ReactNode;
  handleFilter?: () => void;
  searchHandler?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  handleKeyDown?: React.KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
};

const SearchWrapper = styled(Box)`
  border-radius: 4px;
  background: white;
  display: flex;
  align-items: center;
  padding: 9px 14px;
  width: fit-content;
`;

const SearchBar = forwardRef<SearchBarRef, SearchBarPropType>((props, ref) => {
  const {
    searchParam,
    showSearchIcon,
    showFilterListIcon,
    showBorderBottom,
    placeholder,
    sx,
    handleFilter,
    searchHandler,
    handleKeyDown,
    children,
  } = props;

  const { anchorRef, open, setOpen, inputWidth, handleClickAway } =
    useSearchBar(ref);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <SearchWrapper
        sx={{
          ...searchBar,
          ...(showBorderBottom && searchBar.showBorderBottomStyle),
          ...sx,
        }}
        ref={anchorRef}
      >
        {showSearchIcon && <SearchIcon />}
        <InputBase
          value={searchParam}
          placeholder={placeholder}
          onChange={searchHandler}
          onKeyDown={handleKeyDown}
          onFocus={() => {
            setOpen(true);
          }}
        />
        {open && (
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            style={{ zIndex: 1, width: inputWidth }}
          >
            <Paper sx={{ width: "100%" }}>{children}</Paper>
          </Popper>
        )}
        {showFilterListIcon && (
          <Button
            variant="text"
            sx={{ minWidth: "auto", color: "grey.50" }}
            onClick={() => {
              handleFilter();
              const intervalId = setInterval(() => {
                const tooltipPanel = document.querySelector(
                  '.MuiDataGrid-panel[role="tooltip"]'
                ) as HTMLElement | null;

                if (tooltipPanel) {
                  tooltipPanel.classList.add("rolesSearchBarFilter");
                  clearInterval(intervalId);
                }
              }, 0);
            }}
          >
            <FilterListIcon />
          </Button>
        )}
      </SearchWrapper>
    </ClickAwayListener>
  );
});

export default SearchBar;
