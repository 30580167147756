export const personnelStatus = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const shiftTime = [
  { label: "8 am - 5 pm", value: "8 am - 5 pm" },
  { label: "9 am - 6 pm", value: "9 am - 6 pm" },
  { label: "10 am - 7 pm", value: "10 am - 7 pm" },
];

export const genders = [
  { label: "M", value: "M" },
  { label: "F", value: "F" },
];

export const preferredContacts = [
  { label: "Mobile", value: "Mobile" },
  { label: "Phone", value: "Phone" },
];
