import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { LIST_VIEW_HEADER_TYPE, SEARCH_PLACEHOLDERS } from "appConstants/index";
import { APP_ROUTES } from "appConstants/routes";
import useModal from "utils/hooks/useModal";
import { useHistory } from "react-router";

const useSearchBar = (listViewHeaderType: LIST_VIEW_HEADER_TYPE) => {
  const history = useHistory();
  let showFilterListIcon, showBulkImport, showCalendar, scanToUpdate;
  let searchPlaceholder = "Search Personnel";
  let showProfileAvatar = true;
  let showSearchBar = true;
  const { isOpen, toggleModal } = useModal();
  const { isImportSuccess, error } = useSelector(
    (state: RootState) => state.personnel
  );

  useEffect(() => {
    if (isImportSuccess || error.title) {
      toggleModal();
    }
  }, [isImportSuccess, error.title, toggleModal]);

  switch (listViewHeaderType) {
    case LIST_VIEW_HEADER_TYPE.PERSONNEL:
      showFilterListIcon = true;
      showBulkImport = true;
      showSearchBar = true;
      break;
    case LIST_VIEW_HEADER_TYPE.ASSIGN_ROLE:
      showProfileAvatar = false;
      showSearchBar = true;
      break;
    case LIST_VIEW_HEADER_TYPE.LIST_ROLE:
    case LIST_VIEW_HEADER_TYPE.QUOTES:
      showFilterListIcon = true;
      searchPlaceholder = "Search";
      showSearchBar = true;
      break;
    case LIST_VIEW_HEADER_TYPE.MERGE:
      showProfileAvatar = false;
      showFilterListIcon = false;
      showSearchBar = true;
      break;
    case LIST_VIEW_HEADER_TYPE.ASSET:
      showProfileAvatar = true;
      showFilterListIcon = true;
      showBulkImport = true;
      showSearchBar = true;
      searchPlaceholder = SEARCH_PLACEHOLDERS.ASSETS;
      break;
    case LIST_VIEW_HEADER_TYPE.DEALERSHIP:
      showProfileAvatar = true;
      showSearchBar = false;
      break;
    case LIST_VIEW_HEADER_TYPE.DISPATCH:
      showProfileAvatar = true;
      showFilterListIcon = true;
      showBulkImport = false;
      showCalendar = true;
      scanToUpdate = true;
      searchPlaceholder = SEARCH_PLACEHOLDERS.DISPATCH;
      break;
    case LIST_VIEW_HEADER_TYPE.PURCHASE:
      showProfileAvatar = true;
      showFilterListIcon = true;
      showBulkImport = false;
      searchPlaceholder = SEARCH_PLACEHOLDERS.PURCHASE;
      break;
    case LIST_VIEW_HEADER_TYPE.TRANSACTION_LISTING:
      showProfileAvatar = false;
      showFilterListIcon = false;
      showBulkImport = false;
      showSearchBar = false;
      break;
    default:
      break;
  }

  const handleDispatchCalendar = () => {
    history.push(APP_ROUTES.ViewDispatchCalendar);
  };

  return {
    showFilterListIcon,
    showBulkImport,
    showCalendar,
    scanToUpdate,
    searchPlaceholder,
    showProfileAvatar,
    showSearchBar,
    isOpen,
    toggleModal,
    handleDispatchCalendar,
  };
};

export default useSearchBar;
