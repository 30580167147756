import { colorSet } from "appConstants/index";
import { useState } from "react";

const useChipGenerator = (
  onSelect: (color: string) => void,
  colorValue: string
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState(colorValue || colorSet[0].toString());
  const [checkedFontColor, setCheckedFontColor] = useState("");
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setValue(selectedValue);
    onSelect(selectedValue);
    setCheckedFontColor("");
  };

  const handleFontChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setCheckedFontColor(selectedValue);
    let currentFontColor = value.split(", ");
    currentFontColor[1] = selectedValue;
    setValue(currentFontColor.join(", "));
    onSelect(currentFontColor.join(", "));
  };

  return {
    colorSet,
    open,
    value,
    anchorEl,
    checkedFontColor,
    handleClick,
    handleClose,
    handleChange,
    handleFontChange,
  };
};

export default useChipGenerator;
