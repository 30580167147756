import { Libraries } from "@react-google-maps/api";
import { PURCHASING_ITEMS_TYPE } from "./types";
import FORM_FIELDS from "./formFields";

const { PURCHASE_PRICE, CURRENT_FLOOR_PLAN_BALANCE, FLOOR_PLAN_COMPANY } =
  FORM_FIELDS;

export * from "./messages/successMessages";
export * from "./routes";
export * from "./finance";
export * from "./formFields";
export * from "./inventory";
export * from "./personnel";
export * from "./security";
export * from "./styles";
export * from "./types";
export * from "./FormFields/dealershipFormFields";
export * from "./FormFields/dispatchFormFields";
export * from "./FormFields/personnelFormFields";
export * from "./FormFields/quoteFormFields";

export const LOCAL_STORAGE_KEYS = {
  TOKEN: "token",
  EMAIL: "email",
  TIME: "timerStartTime",
  REFRESH_TOKEN: "refreshToken",
};

export enum LIST_VIEW_HEADER_TYPE {
  PERSONNEL = "personnel",
  ASSET = "asset",
  DEALERSHIP = "dealership",
  LIST_ROLE = "role",
  ASSIGN_ROLE = "assignRole",
  CREATE_ROLE = "createRole",
  MERGE = "merge",
  LIST_TIMECLOCK = "timeclock",
  DISPATCH = "dispatch",
  PURCHASE = "purchase",
  TRANSACTION_LISTING = "transactionListing",
  QUOTES = "quotes",
}

export const DATE_TIME_FORMAT = {
  date: {
    D_MMM_YYYY_FORMAT: "D MMM, YYYY",
    D_MMM_YYYY_HMA_FORMAT: "D MMM, YYYY h:mm A",
    MM_DD_YYYY_FORMAT: "MM/DD/YYYY",
    YYYY_MM_DD_WITH_HYPHENS: "YYYY-MM-DD",
    YYYY_MM_DD_WITH_TZ: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  },
  time: { HH_MM: "HH:mm", HH_MM_SS: "HH:mm:ss", HMA: "h:mm A" },
};

export enum PERMISSION_TYPE {
  TIME_BASED = "time-based",
  PERMANENT = "permanent",
}

export { default as errorGraphic } from "./../images/error.svg";

export enum STATUS_CODE {
  SUCCESS = 200,
  CREATED_SUCCESS = 201,
  UPDATE_SUCCESS = 204,
  NOT_FOUND = 404,
}

export const MULTIPART_HEADER = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const JSON_HEADER = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const searchableColumnsForAssets = ["assetName", "vin", "stockNumber"];
export enum SEARCH_PLACEHOLDERS {
  ASSETS = "Search Assets",
  DISPATCH = "Search Dispatch",
  PURCHASE = "Search ",
  DISPATCH_CALENDAR = "Search Scheduled Deliveries",
}

export const IMAGE_BASE64_PREFIX = "data:image/jpeg;base64,";

export const DISPATCH_TYPES = {
  Internal: {
    id: 1,
    label: "Intra-Transfer",
  },
  External: {
    id: 2,
    label: "External",
  },
  Customer: {
    id: 3,
    label: "Customer",
  },
  Manufacturer: {
    id: 4,
    label: "Manufacturer",
  },
};

export const ERROR_MESSAGES = {
  // Roles
  getRole: "Error while getting role",
  getRoles: "Error while getting roles",
  createRole: "Error while creating role",
  assignRole: "Error while assigning role",
  editRole: "Error while editing role",
  getPermissions: "Error while getting permissions",
  // Personnel
  getAllPersonnels: "Error while getting personnel list",
  getPersonnel: "Error while getting personnel",
  getLocations: "Error while getting locations",
  getPorters: "Error while getting porters",
  mergePersonnels: "Error while merging personnel",
  uploadPersonnelBulkImportFile: "Error while bulk personnel import",
  getCountries: "Error while getting countries",
  getRegions: "Error while getting regions",
  getStates: "Error while getting states",
  getCounties: "Error while getting counties",
  getManagers: "Error while getting managers",
  createPersonnel: "Error while creating personnel",
  roleSelection: "Please select at least one role",
  editPersonnel: "Error while editing personnel",
  getDepartments: "Error while getting departments",
  notifyUserEmail: "Error while sending notification email",
  getIPAddress: "Error fetching IP address:",
  getAttendances: "Error while getting attdentances",
  createCheckInTime: "Error while check in time",
  editCheckOutTime: "Error while check out time",
  editTimeSheet: "Error while edit time sheet",
  editDND: "Error while do not disturb",
  getCurrentUser: "Error while getting current user",

  //Dealership
  getAddressType: "Error while getting address types",
  getPhoneType: "Error while getting phone types",
  getEmailType: "Error while getting email types",
  getWebType: "Error while getting web types",
  getAPIType: "Error while getting api types",
  getAllDealerships: "Error while getting dealership(s)",
  createDealership: "Error while creating dealership",
  updateDealership: "Error while updating dealership",
  deleteDealership: "Error while deleting dealership",
  getTenantLocations: "Error while getting tenant locations",

  //Asset
  getAsset: "Error while getting asset",
  createAsset: "Error while creating asset",
  createTemplate: "Error while creating template",
  getAllAsset: "Error while getting assets",
  getAllProductGeneralManagers:
    "Error while getting all product general managers",
  getAssetTemplate: "Error while getting asset template",
  uploadAssetBulkImportFile: "Error while bulk asset import",
  createInventoryAssetError: "Error while creating inventory asset",
  getAllFloorLayoutsError: "Error while getting all floor layouts",
  getAllInventoryError: "Error while getting all inventory",
  getAllSubFloorPlansError: "Error while getting all sub-floor plans",
  getInventoryError: "Error while getting inventory",
  getAllTitleStatusError: "Error while getting all title status",
  getAllPurchaseOrderStatusError:
    "Error while getting all purchase order statuses",
  getAllYearsByTitleStatusError:
    "Error while getting all years by title status",
  getAllAssetTypesByYearError: "Error while getting all asset types by year",
  getAllManufacturersByAssetTypeError:
    "Error while getting all manufacturers by asset type",
  getAllDivisionsByManufacturerError:
    "Error while getting all divisions by manufacturer",
  getAllBrandsByDivisionError: "Error while getting all brands by division",
  getAllSeriesByBrandError: "Error while getting all series by brand",
  getAllModelsBySeriesError: "Error while getting all models by series",
  getAllSubSeriesBySeriesError: "Error while getting all sub-series by series",
  getAllModelsBySubSeriesError: "Error while getting all models by sub-series",
  getAllModelsByBrandError: "Error while getting all models by brand",
  getAllUnitStatusError: "Error while getting all unit status",
  getAllUnitTypesError: "Error while getting all unit types",
  getAllBodyStylesError: "Error while getting all body styles",
  getAllCylinderError: "Error while getting all cylinder",
  getAllTransmissionTypeError: "Error while getting all transmission type",
  getAllFuelTypeError: "Error while getting all fuel type",
  getAllAccessoriesTypeError: "Error while getting all accessories type",
  getAllWarrantyStatusError: "Error while getting all warranty status",
  inventoryBulkImportError: "Error while importing inventory in bulk",
  getAllDivisionsError: "Error while getting all divisions",
  getAllDivisionAllocationsError:
    "Error while getting all division allocations",
  getAllFloorPlanStatusesError: "Error while getting all floor plan statuses",
  getAllProductGeneralManagersError:
    "Error while getting all product general managers",
  getAllProfitCentersError: "Error while getting all profit centers",
  getAllUnitInfoAndPricingOptionsError:
    "Error while getting all unit info And pricing options",
  getAllSellingPriceCategoryError:
    "Error while getting all selling price category",
  getAllFloorPlanCompanyError: "Error while getting all floor plan company",
  getAllManufacturersError: "Error while getting all manufacturers",
  getAllLotLocationsForDealershipError:
    "Error while getting all lot locations for dealership",
  getAllLotBuildingsByLotLocationError:
    "Error while getting all lot buildings by lot location",
  addAssetTemplateError: "Error while adding asset template",
  createTransactionError: "Error while creating transaction",
  editAssetError: "Error while editing asset",
  getAllTransportersError: "Error while getting all transporters",
  getAllUnlinkedItemsError: "Error while getting all unlinked items",
  getAllTransactionsError: "Error while getting all transactions",
  getTransactionsByReferenceNoError:
    "Error while getting transactions by reference number",
  updateTransactionPostingError: "Error while updating transaction posting",
  getAssetHistoryDetailsError: "Error while getting asset history details",
  getAssetHistoryError: "Error while getting asset history",
  getAssetByStockNumberError: "Error while getting asset by stock number",

  //Dispatch
  getAllDispatchItems: "Error while getting dispatch items",
  getAllDispatchNotes: "Error while getting dispatch notes",
  createDispatch: "Error while creating dispatch",
  editDispatch: "Error while editing dispatch",
  getDispatchItem: "Error while getting dispatch",
  updateDispatchStatus: "Error while updating dispatch staus",
  noProfitCenter: "Source Profit Center for the selected asset does not exist",
  sameProfitCenters: "Source and Destination Profit Centers cannot be same",
  noLot: "Source Lot for the selected asset does not exist",
  noLotLocation: "Source Lot Location for the selected asset does not exist",
  sameLotLocations: "Source and Destination Lot Locations cannot be same",
  scanFailed:
    "Failed to Complete! Please be sure the label is not damaged or obscured",

  //Purchase Orders
  createPurchaseOrder: "Error while creating Purchase order.",
  editPurchaseOrder: "Error while editing Purchase order.",
  getPurchaseOrder: "Error while getting Purchase order(s).",
  getPurchaseOrderHistory: "Error while getting Purchase order(s) history.",
  getAssetTemplateById: "Error while getting Asset template(s)",
  getAssetTemplateHistoryById: "Error while getting Asset template(s) history",

  // Finance
  getQuotes: "Error while getting quotes",
  getBuyers: "Error while getting buyers",

  //Accounting
  createTransaction: "Error while creating Transaction.",

  //auth
  resetPassword: "Error while reseting password",
  authenticateCode: "Error while authenticating code",
  authenticate: "Error while authenticating",
  getUserPersmissions: "Error while getting user permissions",
};

export const TIMECLOCK_MESSAGES = {
  shiftTimeCompleted: "Your next shift will again start in 16 hours.",
  shiftTimeComplete: "You have completed 0 hrs. of your shift",
  // eslint-disable-next-line no-template-curly-in-string
  remainingShiftTime:
    "You have completed <span class='fw-semibold'>${remainingHours} hrs.</span> of your shift.",
  shiftTimeNotStarted: "Your shift time has not started yet.",
  shiftTimeEnded: "Your shift time has ended.",
};

export const WORKING_HOUR = 9;

export const PURCHASING_MENU_ITEMS: Array<PURCHASING_ITEMS_TYPE> = [
  { label: "Print Purchase" },
  { label: "Duplicate Purchase" },
  { label: "Update Status" },
  { label: "Copy Link" },
  { label: "View History" },
];

export enum CURRENCYSIGN {
  DOLLAR = "$",
  POUND = "£",
}

export enum UnitSystemEnum {
  IMPERIAL = 1,
  METRIC = 2,
}

export enum AccountType {
  Purchase = 1,
  PurchaseWithDestination = 2,
  Floorplan = 3,
  FloorplanWithDestination = 4,
}

export enum AssetCondition {
  "Excellent" = 1,
  "Very Good" = 2,
  "Good" = 3,
  "Fair" = 4,
}

export enum DISPLAY_MODE {
  "POPUP" = "popup",
  "LISTING" = "listing",
  "VIEW" = "view",
}

export const MIN_UNIT_YEAR = 2020;

export enum CreateDealershipFormTab {
  GeneralInfo = "General Info",
  Addresses = "Addresses",
  Phones = "Phones",
  Emails = "Emails",
  Web = "Web",
  Hours = "Hours",
  API = "API",
  Settings = "Settings",
}

export const SEQUENCES_TO_KEEP_AS_IT_IS = ["SMTP"];

// Colors set [Start]
export const colorSet = [
  ["primary.400, grey.A400"],
  ["success.400, grey.A400"],
  ["supportingColor.200, grey.50"],
  ["supportingColorAlt.400, grey.50"],
  ["supportingColorAlt.500, grey.50"],
  ["supportingColorAlt.600, grey.50"],
  ["supportingColor.50, grey.A400"],

  ["supportingColor.100, grey.A400"],
  ["supportingColorAlt.700, grey.50"],
  ["supportingColor.500, grey.50"],
  ["supportingColorAlt.800, grey.50"],
  ["appModules.400, grey.50"],
  ["supportingColorAlt.200, grey.50"],

  ["supportingColor.300, grey.A400"],
  ["supportingColorAlt.900, grey.A400"],
  ["supportingColorAlt.A100, grey.A400"],
  ["supportingColor.400, grey.50"],
  ["supportingColor.700, grey.A400"],
  ["supportingColor.800, grey.A400"],

  ["supportingColor.A100, grey.A400"],
  ["supportingColor.900, grey.A400"],
  ["supportingColor.A200, grey.50"],
  ["supportingColor.A400, grey.A400"],
  ["supportingColor.A700, grey.50"],
  ["supportingColorAlt.100, grey.A400"],

  ["supportingColorAlt.300, grey.A400"],
  ["secondary.400, grey.A400"],
  ["secondary.300, grey.A400"],
  ["tertiary.400, grey.A400"],
  ["secondary.100, grey.A400"],
];

export enum LotType {
  Parking = 0,
  Showroom = 1,
  Storage = 2,
  Service = 3,
}

export const DYNAMIC_GRID_FIELDS_TO_HIDE = {
  assetId: true,
  id: true,
};

export enum DynamicGridColumnsForModification {
  IMAGE = "image",
  SELLING_PRICE = "sellingPrice",
  MSRP = "msrp",
  VIN = "vin",
}
export enum KEYS {
  ENTER = "Enter",
}
export enum TIMEOUT {
  DEBOUNCE_TIMEOUT = 500,
  REFETCH_TIMEOUT = 3000,
}

export const DYNAMIC_GRID_COLUMNS_REQUIRE_MODIFICATION = {
  [DynamicGridColumnsForModification.IMAGE]: true,
  [DynamicGridColumnsForModification.SELLING_PRICE]: true,
  [DynamicGridColumnsForModification.MSRP]: true,
};

export const ONLY_CONVERT_UPPERCASE_WORDS = {
  [DynamicGridColumnsForModification.MSRP]: true,
  [DynamicGridColumnsForModification.VIN]: true,
};

export enum DEFAULT_FIELD_VALUE {
  UNIT_STATUS_FOR_TEMPLATE = "Template", //For Vehicle Status Template
  UNIT_STATUS_FOR_SHIPPED = "Shipped", //For Vehicle Status Shipped
  PO_STATUS_FOR_NOT_ORDERED = "Not Ordered", //For PO Status Not Ordered
  PO_STATUS_FOR_PURCHASED = "Purchased", //For PO Status Purchased
}

export enum ACTIONS {
  EDIT = "edit",
  DUPLICATE = "duplicate",
}

export const SUCCESS_MESSAGE_TIMEOUT = 5; // INFO: In Seconds

export enum Country {
  US = "US",
  UnitedStates = "United States",
  Canada = "Canada",
}

export enum GLAccountType {
  InventoryGLAccount = 1,
  AdditionalCostsGLAccount = 2,
  FloorplanGLAccount = 3,
}

export const glAccountTypeLabels: Record<GLAccountType, string> = {
  [GLAccountType.InventoryGLAccount]: "Inventory Account",
  [GLAccountType.AdditionalCostsGLAccount]: "Additional Cost Account",
  [GLAccountType.FloorplanGLAccount]: "Floorplan Interest Account",
};

export const GOOGLE_ADDRESS_LIBRARIES: Libraries = ["places"];
export const GOOGLE_ADDRESS_RESTRICTIONS = { country: ["us", "ca"] };
export const GOOGLE_ADDRESS_FIELDS = ["address_components", "geometry"];

export enum GOOGLE_ADDRESS_TYPE {
  STREET_NUMBER = "street_number",
  ROUTE = "route",
  POSTAL_CODE = "postal_code",
  LOCALITY = "locality",
  COUNTRY = "country",
  ADMINISTRATIVE_AREA_LEVEL_1 = "administrative_area_level_1",
}

export enum MUI_GRID_COLUMNS {
  CHECKBOXES = "__check__",
  ACTION = "action",
}

export enum TimeOptions {
  OneWeek = "1 week",
  OneHour = "1 hr",
  FifteenDays = "15 days",
  OneMonth = "1 month",
  TwoMonths = "2 month",
}

export const TimeOptionsArray = [
  { label: TimeOptions.OneWeek, value: TimeOptions.OneWeek },
  { label: TimeOptions.OneHour, value: TimeOptions.OneHour },
  { label: TimeOptions.FifteenDays, value: TimeOptions.FifteenDays },
  { label: TimeOptions.OneMonth, value: TimeOptions.OneMonth },
  { label: TimeOptions.TwoMonths, value: TimeOptions.TwoMonths },
];

export const NO_ROW_MESSAGE = "No rows";

export const assetDataMissingFields = [
  { field: PURCHASE_PRICE, label: "COST Purchase Price" },
  { field: CURRENT_FLOOR_PLAN_BALANCE, label: "Current Floorplan Balance" },
  { field: FLOOR_PLAN_COMPANY, label: "Floorplan Company" },
];

export enum QUERY_PARAM_KEY {
  IS_EDIT = "isEdit",
  ID = "id",
  MODE = "mode",
}

export enum STATUS_VALUE {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum DAY {
  SUNDAY = "Sunday",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
}

export enum IMPERIAL_EXAMPLES_LABEL {
  FT_IN = "e.g. 8 ft 2 in",
}
