import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { Button } from "components";
import {
  linkButtonGrey50,
  pxToRem,
  simpleDashheadingStyle,
} from "appConstants/styles";

interface Props {
  title?: string;
  subTitle?: string | ReactElement;
  btnTitle?: string;
  btnIcon?: ReactElement;
  btnHandleClick?: React.MouseEventHandler<HTMLButtonElement>;
  isBtnDisable?: boolean;
  sx?: object;
}

const SimpleDashHeading: React.FC<Props> = (props) => {
  const {
    title = "Simple heading",
    subTitle,
    btnTitle,
    btnIcon,
    btnHandleClick,
    isBtnDisable = false,
    sx,
    children,
  } = props;
  const buttonicon = btnIcon || null;

  return (
    <Box
      sx={{
        ...simpleDashheadingStyle,
        ...sx,
        ...(btnTitle || subTitle
          ? {}
          : {
              "h5.MuiTypography-root.MuiTypography-h5": {
                "&:after": {
                  margin: pxToRem(7, 0, 0, 17),
                },
              },
            }),
      }}
    >
      <Typography variant="h5" sx={simpleDashheadingStyle.headingStyle}>
        {title}
      </Typography>
      {btnTitle && (
        <Button
          startIcon={buttonicon}
          variant="text"
          sx={linkButtonGrey50}
          onClick={btnHandleClick}
          disabled={isBtnDisable}
        >
          {btnTitle}
        </Button>
      )}
      {children}
      {subTitle && <Typography variant="body1">{subTitle}</Typography>}
    </Box>
  );
};

export default SimpleDashHeading;
