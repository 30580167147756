import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_ALL_ROLES_URL,
  CREATE_ROLE_URL,
  GET_ROLE_URL,
  GET_ALL_PERMISSIONS_URL,
  EDIT_ROLE_URL,
  ASSIGN_ROLE_URL,
} from "utils/urls";
import {
  CreateRolePayload,
  GetViewRolePayload,
  GetFilteredOrSearchedRolesPayload,
  AssignRolePayload,
} from "types/role";
import axiosClient from "utils/axiosClient";
import {
  ERROR_MESSAGES,
  STATUS_CODE,
  inventorySuccessMessages,
} from "appConstants/index";
import AppError from "utils/AppError";
import { generateQueryParams } from "utils/common";

export const getAllRoles = createAsyncThunk(
  GET_ALL_ROLES_URL,
  async (payload: GetFilteredOrSearchedRolesPayload) => {
    try {
      const queryParams = generateQueryParams({
        page: payload.page,
        search: payload.search,
        filter: payload.filter,
        field: payload.field,
        sort: payload.sort,
      });

      const response = await axiosClient.get(
        `${GET_ALL_ROLES_URL}?${queryParams}`
      );
      const { data } = response;
      let rolesList = data.records.map((role, index) => {
        return {
          id: `${role.roleName}-${index}`,
          ...role,
          roleBasedType: role.roleBasedType.toLowerCase(),
        };
      });
      return {
        records: rolesList,
        totalRecords: response.data.totalRecords,
      };
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getRoles, error.response.data?.message);
    }
  }
);

export const getRole = createAsyncThunk(
  GET_ROLE_URL,
  async (payload: GetViewRolePayload) => {
    try {
      const response = await axiosClient.get(GET_ROLE_URL, {
        params: payload,
      });
      const { data } = response;
      return data[0];
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getRole, error.response.data?.message);
    }
  }
);

export const createRole = createAsyncThunk(
  CREATE_ROLE_URL,
  async (payload: CreateRolePayload) => {
    try {
      const response = await axiosClient.post(CREATE_ROLE_URL, payload);
      if (response.status === STATUS_CODE.SUCCESS)
        return response.data.status.message;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.createRole, error.response.data);
    }
  }
);

export const getAllPermissions = createAsyncThunk(
  GET_ALL_PERMISSIONS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_PERMISSIONS_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPermissions,
        error.response.data?.message
      );
    }
  }
);

export const editRole = createAsyncThunk(
  EDIT_ROLE_URL,
  async (payload: CreateRolePayload) => {
    try {
      const response = await axiosClient.put(EDIT_ROLE_URL, payload);
      if (response.status === STATUS_CODE.SUCCESS)
        return response.data.status.message;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.editRole, error.response.data?.message);
    }
  }
);

export const assignRole = createAsyncThunk(
  ASSIGN_ROLE_URL,
  async (payload: AssignRolePayload) => {
    try {
      const response = await axiosClient.put(ASSIGN_ROLE_URL, payload);
      if (response.status === STATUS_CODE.UPDATE_SUCCESS) {
        return inventorySuccessMessages.assignRole;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.assignRole,
        error.response.data?.message
      );
    }
  }
);
