import { ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  Typography,
} from "@mui/material";
import { pxToRem, textFiledStyle } from "appConstants/styles";

type ItemsType = {
  label: string | number | ReactNode;
  value: string | number;
};
type SelectWrapperPropType = {
  label?: string;
  items: any[] | ItemsType[];
  itemLabelField?: string; // INFO: this is used to make it more dynamic so that __
  itemValueField?: string; // __ we can pass any data array and only need to tell the vale and label fields or keys;
  variant?: "filled" | "outlined" | "standard";
  menuItemSx?: SxProps;
} & SelectProps;

const SelectWrapper = (props: SelectWrapperPropType) => {
  const {
    label,
    items,
    itemLabelField = "label",
    itemValueField = "value",
    variant,
    menuItemSx,
    ...selectProps
  } = props;

  const isSimpleStringArray = typeof items[0] === "string";

  return (
    <FormControl
      fullWidth
      variant={variant ?? "filled"}
      sx={{
        ...textFiledStyle,
        ...(!label && {
          "p.MuiTypography-root.MuiTypography-body1": {
            mt: pxToRem(-5),
          },
        }),
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select {...selectProps}>
        {items.map((item) => (
          <MenuItem
            key={isSimpleStringArray ? item : item[itemValueField]}
            value={isSimpleStringArray ? item : item[itemValueField]}
            sx={menuItemSx}
          >
            <Typography variant="body1">
              {isSimpleStringArray ? item : item[itemLabelField]}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectWrapper;
