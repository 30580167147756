import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { pxToRem, uploadBox } from "appConstants/styles";

const VisuallyHiddenInput = styled("input")({
  opacity: 0,
  width: "100%",
  height: "100%",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: 10,
});

type InputFileUploadProps = {
  onFileUploadChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputFileUpload = (props: InputFileUploadProps) => {
  const { onFileUploadChange } = props;

  return (
    <Box sx={uploadBox} component="label">
      <UploadFileOutlinedIcon
        sx={{
          color: "greyVariantAlt.700",
          fontSize: pxToRem(55),
          opacity: 0.5,
        }}
      />
      <Typography
        sx={{
          fontSize: pxToRem(14),
          color: "greyVariantAlt.700",
          lineHeight: pxToRem(20),
          letterSpacing: pxToRem(0.1),
          fontWeight: "bold",
          marginTop: pxToRem(10),
        }}
      >
        Click to this area to Upload
      </Typography>
      <VisuallyHiddenInput
        type="file"
        onChange={onFileUploadChange}
        accept="image/*"
      />
    </Box>
  );
};

export default InputFileUpload;
