import { Checkbox, Stack, SxProps, Typography } from "@mui/material";
import { dealerInfoTileStyle, pxToRem } from "appConstants";
import LockSharpIcon from "@mui/icons-material/LockSharp";
import CheckIcon from "@mui/icons-material/Check";
import Button from "components/Button";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";

type DealerInfoTilePropType = {
  title: string;
  sx?: SxProps;
  color?: string;
  checked?: boolean;
  dateTime?: string;
  onClick?: () => void;
};

const DealerInfoTile: React.FC<DealerInfoTilePropType> = (props) => {
  const {
    title = "Dealer Account Name",
    sx,
    color = "grey.A400",
    checked,
    dateTime = "08/17/24 | 3:03pm",
    onClick = () => {},
  } = props;

  return (
    <Stack
      flex={1}
      direction={"row"}
      alignItems={"center"}
      minHeight={pxToRem(43)}
      sx={{
        ...dealerInfoTileStyle,
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack sx={{ mr: pxToRem(17), mb: pxToRem(2) }}>
        <Checkbox
          sx={{ px: 0 }}
          icon={
            <LockSharpIcon
              sx={{
                fontSize: pxToRem(17),
                color: color ?? "grey.50",
              }}
            />
          }
          checkedIcon={
            <Stack sx={dealerInfoTileStyle.checkedIconWrapper}>
              <CheckIcon
                sx={dealerInfoTileStyle.checkedIconWrapper.checkedIcon}
              />
            </Stack>
          }
          checked={checked}
        />
      </Stack>
      <Typography
        fontSize={pxToRem(14)}
        letterSpacing={pxToRem(0.1)}
        fontWeight={500}
        flex={1}
      >
        {title}
      </Typography>
      <Stack display="flex" direction="column" alignItems="flex-end">
        <Typography variant="body2" fontSize={pxToRem(10)}>
          {dateTime}
        </Typography>
        <Button
          onClick={() => {}}
          variant="text"
          startIcon={
            <TrackChangesIcon
              sx={{ height: pxToRem(11), width: pxToRem(11) }}
            />
          }
          sx={{
            color: color ?? "grey.50",
            ...dealerInfoTileStyle.rightButtonStyle,
          }}
        >
          View Log
        </Button>
      </Stack>
    </Stack>
  );
};

export default DealerInfoTile;
