import { Box, Icon, IconProps, Stack, StackProps } from "@mui/material";
import { ArticleRounded } from "@mui/icons-material";
import { contentCardStyle, pxToRem } from "appConstants/styles";

interface ContentCardType extends StackProps {
  icon?: React.FunctionComponentElement<IconProps>;
  children?: React.ReactNode;
  hasIcon?: Boolean;
}
const ContentCard = ({
  icon = <ArticleRounded />,
  children,
  hasIcon = true,
  sx,
  ...otherProps
}: ContentCardType) => {
  return (
    <Stack
      direction={"row"}
      spacing={pxToRem(11)}
      sx={{
        ...contentCardStyle,
        ...sx,
      }}
      {...otherProps}
    >
      {hasIcon && (
        <Box>
          <Icon sx={contentCardStyle.icon}>{icon}</Icon>
        </Box>
      )}
      {children && <Box flex={1}>{children}</Box>}
    </Stack>
  );
};
export default ContentCard;
