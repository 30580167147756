import { DispatchStatus } from "appConstants/inventory";

export const personnelSuccessMessages = {
  personnelCreated: "Success! Personnel Created",
  personnelUpdated: "Success! Personnel Updated",
  personnelBulkUpload: "Success! Personnel Bulk upload successful",
  notifyEmailSuccess: "Notification email sent successfully.",
};

export const inventorySuccessMessages = {
  createAsset: "The Asset was created successfully",
  editAsset: "The Asset was updated successfully",
  createTemplate: "The template was created successfully",
  scanSuccess: "Success! Scan completed with no errors",
  assignRole: "Success! User's Role(s) has been updated successfully",
};

export const dispatchSuccessMessages = {
  createDispatch: "Success! Assets added for dispatch",
  editDispatch: "Success! Dispatch updated",
  addNote: "Success! Note added",
  [DispatchStatus.Cancel]: "Success! Dispatch Cancelled",
  [DispatchStatus.Completed]: "Success! Dispatch Completed",
};

export const dealershipSuccessMessages = {
  createDealership: "Success! Dealership Created",
  updateDealership: "Success! Dealership Updated",
};
