import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CREATE_DISPATCH_URL,
  EDIT_DISPATCH_URL,
  GET_ALL_DISPATCH_URL,
  GET_DISPATCH_URL,
  ADD_DISPATCH_NOTE_URL,
  UPDATE_DISPATCH_STATUS_URL,
  GET_DISPATCH_BY_MONTH_URL,
} from "utils/urls";
import axiosClient from "utils/axiosClient";
import {
  Dispatch,
  DispatchStatus,
  GetDispatchByMonthPayload,
  GetFilteredOrSearchedDispatchPayload,
  GetViewDispatchPayload,
} from "types/dispatch";
import {
  DISPATCH_TYPES,
  ERROR_MESSAGES,
  JSON_HEADER,
  STATUS_CODE,
} from "../../../appConstants";
import AppError from "utils/AppError";
import { generateQueryParams } from "utils/common";

export const getAllDispatchItems = createAsyncThunk(
  GET_ALL_DISPATCH_URL,
  async (payload: GetFilteredOrSearchedDispatchPayload) => {
    try {
      const queryParams = generateQueryParams({
        page: payload.page,
        search: payload.search,
        field: payload.field,
        sort: payload.sort,
        filter: payload.filter,
      });

      const response = await axiosClient.get(
        `${GET_ALL_DISPATCH_URL}?${queryParams}`
      );
      return {
        records: response.data?.records,
        totalRecords: response.data.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllDispatchItems,
        error.response.data?.message
      );
    }
  }
);

export const createDispatch = createAsyncThunk(
  CREATE_DISPATCH_URL,
  async (payload: any) => {
    try {
      const response = await axiosClient.post(
        CREATE_DISPATCH_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        if (response.data[0].dispatchType === DISPATCH_TYPES.Manufacturer.id) {
          const dispatchDetailsId = response.data.map(
            (dispatch: Dispatch) => dispatch.dispatchDetailsId
          );
          return dispatchDetailsId;
        }
        return;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createDispatch,
        error.response.data?.message
      );
    }
  }
);

export const getDispatchItem = createAsyncThunk(
  GET_DISPATCH_URL,
  async (payload: GetViewDispatchPayload) => {
    try {
      const response = await axiosClient.get(GET_DISPATCH_URL, {
        params: payload,
      });
      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getDispatchItem,
        error.response.data?.message
      );
    }
  }
);

export const addDispatchNote = createAsyncThunk(
  ADD_DISPATCH_NOTE_URL,
  async (payload: any) => {
    try {
      const response = await axiosClient.post(
        ADD_DISPATCH_NOTE_URL,
        payload,
        JSON_HEADER
      );
      if (response.status === STATUS_CODE.CREATED_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllDispatchNotes,
        error.response.data?.message
      );
    }
  }
);

export const updateDispatchStatus = createAsyncThunk(
  UPDATE_DISPATCH_STATUS_URL,
  async (payload: DispatchStatus) => {
    try {
      const response = await axiosClient.put(
        UPDATE_DISPATCH_STATUS_URL,
        payload
      );

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.updateDispatchStatus,
        error.response.data?.message
      );
    }
  }
);

export const editDispatch = createAsyncThunk(
  EDIT_DISPATCH_URL,
  async (payload: any) => {
    try {
      const response = await axiosClient.put(
        EDIT_DISPATCH_URL,
        payload,
        JSON_HEADER
      );
      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.editDispatch,
        error.response.data?.message
      );
    }
  }
);

export const getDispatchItemsByMonth = createAsyncThunk(
  GET_DISPATCH_BY_MONTH_URL,
  async (payload: GetDispatchByMonthPayload) => {
    try {
      const response = await axiosClient.get(GET_DISPATCH_BY_MONTH_URL, {
        params: payload,
      });
      return {
        records: response.data?.records,
        totalRecords: response.data.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getDispatchItem,
        error.response.data?.message
      );
    }
  }
);
