import { createSlice } from "@reduxjs/toolkit";
import { DealershipState } from "./dealership.model";
import {
  getAddressType,
  getEmailType,
  getPhoneType,
  getWebType,
  getAPIType,
  getAllDealerships,
  createDealership,
  getDealershipById,
  getTenantLocations,
  updateDealership,
} from "./dealership.action";
import {
  addressTypesFulfilled,
  addressTypesRejected,
  emailTypesFulfilled,
  emailTypesRejected,
  phoneTypesFulfilled,
  phoneTypesRejected,
  webTypesFulfilled,
  webTypesRejected,
  apiTypesFulfilled,
  apiTypesRejected,
  allDealershipFulfilled,
  allDealershipRejected,
  allLocationsFulfilled,
  allLocationsRejected,
  createDealershipFulfilled,
  createDealershipRejected,
  getDealreshipFulfilled,
  getDealreshipRejected,
  updateDealershipFulfilled,
  updateDealershipRejected,
} from "./dealership.reducer";

const initialState: DealershipState = {
  isLoading: false,
  error: { title: "", description: "" },
  addressTypes: [],
  phoneTypes: [],
  emailTypes: [],
  webTypes: [],
  apiTypes: [],
  allDealerships: [],
  allLocations: [],
  dealership: null,
  successMessage: "",
};

export const dealershipSlice = createSlice({
  name: "dealership",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      // Reset the properties you want to reset to their initial values
      return {
        ...initialState,
      };
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    resetSuccess: (state) => {
      state.successMessage = "";
    },
    resetDealershipById: (state) => {
      state.dealership = null;
    },
  },
  extraReducers: (builder) => (
    builder.addCase(getAddressType.pending, (state, action) => {
      state.isLoading = true;
      state.successMessage = "";
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAddressType.fulfilled, (state, action) => {
      addressTypesFulfilled(state, action);
    }),
    builder.addCase(getAddressType.rejected, (state, action) => {
      addressTypesRejected(state, action);
    }),
    builder.addCase(getPhoneType.fulfilled, (state, action) => {
      phoneTypesFulfilled(state, action);
    }),
    builder.addCase(getPhoneType.rejected, (state, action) => {
      phoneTypesRejected(state, action);
    }),
    builder.addCase(getEmailType.fulfilled, (state, action) => {
      emailTypesFulfilled(state, action);
    }),
    builder.addCase(getEmailType.rejected, (state, action) => {
      emailTypesRejected(state, action);
    }),
    builder.addCase(getWebType.fulfilled, (state, action) => {
      webTypesFulfilled(state, action);
    }),
    builder.addCase(getWebType.rejected, (state, action) => {
      webTypesRejected(state, action);
    }),
    builder.addCase(getAPIType.fulfilled, (state, action) => {
      apiTypesFulfilled(state, action);
    }),
    builder.addCase(getAPIType.rejected, (state, action) => {
      apiTypesRejected(state, action);
    }),
    builder.addCase(getAllDealerships.fulfilled, (state, action) => {
      allDealershipFulfilled(state, action);
    }),
    builder.addCase(getAllDealerships.rejected, (state, action) => {
      allDealershipRejected(state, action);
    }),
    builder.addCase(getTenantLocations.pending, (state, action) => {
      state.isLoading = true;
      state.successMessage = "";
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getTenantLocations.fulfilled, (state, action) => {
      allLocationsFulfilled(state, action);
    }),
    builder.addCase(getTenantLocations.rejected, (state, action) => {
      allLocationsRejected(state, action);
    }),
    builder.addCase(createDealership.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(createDealership.fulfilled, (state, action) => {
      createDealershipFulfilled(state, action);
    }),
    builder.addCase(createDealership.rejected, (state, action) => {
      createDealershipRejected(state, action);
    }),
    builder.addCase(updateDealership.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(updateDealership.fulfilled, (state, action) => {
      updateDealershipFulfilled(state, action);
    }),
    builder.addCase(updateDealership.rejected, (state, action) => {
      updateDealershipRejected(state, action);
    }),
    builder.addCase(getDealershipById.fulfilled, (state, action) => {
      getDealreshipFulfilled(state, action);
    }),
    builder.addCase(getDealershipById.rejected, (state, action) => {
      getDealreshipRejected(state, action);
      state.dealership = null;
    }),
    builder.addCase(getDealershipById.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    })
  ),
});

export const { reset, resetError, resetSuccess, resetDealershipById } =
  dealershipSlice.actions;
