import { useState } from "react";
import { MoreVertOutlined } from "@mui/icons-material";
import {
  ButtonProps,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import Button from "components/Button";
import { menuObjectType } from "appConstants/types";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import { linkButtonGrey50 } from "appConstants/styles";

type MenuPropType = {
  menuData: menuObjectType;
  menuIcon?: React.ReactNode;
  menuButton?: boolean;
  menuButtonText?: string;
  menuButtonIcon?: React.ReactNode;
  menuButtonEndIcon?: React.ReactNode;
  buttonProps?: ButtonProps;
};

const IconDropDown = (props: MenuPropType) => {
  const {
    menuData,
    menuIcon,
    menuButton,
    menuButtonText,
    menuButtonIcon,
    menuButtonEndIcon,
    buttonProps,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { sx: buttonSx, ...buttonPropsObj } = buttonProps || {};

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (key: number) => {
    const menuItem = menuData ? menuData[key] : null;
    if (menuItem && menuItem.handleClick) {
      menuItem.handleClick();
    }
    handleClose();
  };

  return (
    <>
      {menuButton ? (
        <Button
          startIcon={menuButtonIcon ?? <OfflineBoltIcon />}
          endIcon={menuButtonEndIcon}
          aria-controls={open ? "basic-menu" : "menu-closed"}
          aria-haspopup="true"
          aria-expanded={open ? "true" : "false"}
          variant="text"
          sx={{
            ...linkButtonGrey50,
            fontSize: "12px",
            ...(buttonSx ?? {}),
          }}
          className="active"
          onClick={handleClick}
          {...buttonPropsObj}
        >
          {menuButtonText ?? "Quick Actions"}
        </Button>
      ) : (
        <IconButton
          aria-controls={open ? "basic-menu" : "menu-closed"}
          aria-haspopup="true"
          aria-expanded={open ? "true" : "false"}
          onClick={handleClick}
          sx={{
            color: "greyVariant.200",

            "&:hover, &[aria-expanded='true']": {
              backgroundColor: "grey.50",
              color: "grey.A400",
            },
            ...(buttonSx ?? {}),
          }}
        >
          {!menuIcon ? <MoreVertOutlined /> : menuIcon}
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuList>
          {Object.keys(menuData).map((key) => {
            const menuItem = menuData ? menuData[parseInt(key, 10)] : null;
            if (menuItem && menuItem.divider) {
              return <Divider key={key} />;
            }
            return (
              <MenuItem
                key={key}
                onClick={() => handleMenuItemClick(parseInt(key, 10))}
                disabled={menuItem.isDisabled}
              >
                {menuItem?.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                {menuItem?.label && (
                  <ListItemText>{menuItem.label}</ListItemText>
                )}
                {menuItem?.component}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default IconDropDown;
