import { FC } from "react";
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { datePickerStyle } from "appConstants/styles";
import { convertToDayjsDate } from "utils/common";

type DatePickerWrapperPropType = {
  name?: string;
  label?: string;
  value?: any;
  onChange: (value: any) => void;
} & Partial<DatePickerProps<any>>;

const DatePickerWrapper: FC<DatePickerWrapperPropType> = (props) => {
  const {
    name,
    slotProps,
    label = "Date",
    value = convertToDayjsDate(new Date().toISOString()),
    onChange,
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slotProps={
          slotProps ?? {
            textField: {
              name: name,
            },
          }
        }
        label={label}
        value={value}
        onChange={onChange}
        sx={{ ...datePickerStyle, width: "100%" }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerWrapper;
