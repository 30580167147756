import { FinanceState } from "./finance.model";

export const getQuotesFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.quotes = action.payload?.records;
  state.isLoading = false;
  state.totalRecords = action.payload?.totalRecords;
};

export const getQuotesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const getQuoteByIdFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.quote = action.payload;
};

export const getQuoteByIdRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const getBuyersFullfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.buyers = action.payload?.records;
  state.totalRecords = action.payload?.totalRecords;
};

export const getBuyersRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};
