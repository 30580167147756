import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import { DividerAccordionStyle, pxToRem } from "appConstants/styles";
import HorizontalRuleRounded from "@mui/icons-material/HorizontalRuleRounded";
import ArrowDropUpRounded from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRounded from "@mui/icons-material/ArrowDropDownRounded";

type DividerAccordionPropType = {
  dividerPosition?: "top" | "bottom";
  title?: string;
  dividerSx?: object;
  accordianSx?: object;
  expanded?: boolean;
  onExpandClick?: (expanded: boolean) => void;
};

const DividerAccordion: React.FC<DividerAccordionPropType> = (props) => {
  const {
    expanded,
    children,
    dividerPosition,
    title,
    dividerSx,
    accordianSx,
    onExpandClick,
    ...otherProps
  } = props;

  const [isFilterExpanded, setIsFilterExpanded] = useState(expanded ?? true);

  const handleExpandClick = () => {
    setIsFilterExpanded((preVal) => !preVal);
    if (onExpandClick) {
      onExpandClick(!isFilterExpanded);
    }
  };

  return (
    <>
      {dividerPosition === "top" && (
        <Divider sx={{ ...DividerAccordionStyle.dividerStyle, ...dividerSx }}>
          <Button
            onClick={handleExpandClick}
            sx={DividerAccordionStyle.btnAccord}
          >
            <ArrowDropDownRounded
              sx={{
                transition: "transform 0.3s ease-in-out",
                transform:
                  isFilterExpanded === false ? "rotate(180deg)" : undefined,
              }}
            />
            <HorizontalRuleRounded />
          </Button>
        </Divider>
      )}
      {!!title && (
        <Typography
          variant="body1"
          sx={{
            fontSize: pxToRem(14),
            fontWeight: 500,
            marginBottom: pxToRem(-16),
          }}
        >
          {title}
        </Typography>
      )}
      <Accordion
        expanded={isFilterExpanded}
        sx={{ ...DividerAccordionStyle, ...accordianSx }}
        {...otherProps}
      >
        <AccordionSummary onClick={(e) => e.stopPropagation()} />
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
      {dividerPosition !== "top" && (
        <Divider sx={DividerAccordionStyle.dividerStyle}>
          <Button
            onClick={handleExpandClick}
            sx={DividerAccordionStyle.btnAccord}
          >
            <ArrowDropUpRounded
              sx={{
                transition: "transform 0.3s ease-in-out",
                transform:
                  isFilterExpanded === false ? "rotate(180deg)" : undefined,
              }}
            />
            <HorizontalRuleRounded />
          </Button>
        </Divider>
      )}
    </>
  );
};

export default DividerAccordion;
