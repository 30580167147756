import { useState, useEffect } from "react";

const useExpandAbleHeading = (expanded: boolean = false) => {
  const [expand, setExpand] = useState(false);

  useEffect(() => setExpand(expanded), [expanded]);

  return { expand, setExpand };
};

export default useExpandAbleHeading;
