import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { menuObjectType } from "appConstants/types";
import { APP_ROUTES } from "appConstants/routes";
import { Html5Qrcode } from "html5-qrcode";
import { useHistory } from "react-router-dom";
import { getAssetByStockNumber } from "redux/slices/inventory/inventory.action";
import {
  resetAssetByStockNumber,
  setSelectedAssets,
} from "redux/slices/inventory/inventory.slice";
import { CheckSharp, InsertLinkRounded, Place } from "@mui/icons-material";
import { updateDispatchStatus } from "redux/slices/dispatch/dispatch.action";
import { resetSuccess } from "redux/slices/dispatch/dispatch.slice";
import useModal from "utils/hooks/useModal";
import useText from "utils/hooks/useText";
import { DispatchStatus } from "appConstants/inventory";

const qrConfig = { fps: 10 };
let html5QrCode: Html5Qrcode;

const useScanToUpdate = () => {
  const { isOpen, toggleModal } = useModal();
  const { scanToUpdateBtn } = useText("scanToUpdate");

  const [showQRImage, setShowQRImage] = useState(true);
  const [scannedText, setScannedText] = useState(null);
  const [scanInProgress, setScanInProgress] = useState(false);
  const [scanLabel, setScanLabel] = useState("Scan");

  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const { isLoading, assetByStockNumber } = useSelector(
    (state: RootState) => state.asset
  );

  const { statusLoading, statusSuccessMessage } = useSelector(
    (state: RootState) => state.dispatch
  );

  const { asset, dispatchDetails } = assetByStockNumber;

  useEffect(() => {
    closeCamera();
  }, [dispatch]);

  const startQRCode = () => {
    resetInitials();
    const readerElement = document.getElementById("reader");
    if (readerElement) {
      const readerId = readerElement.id;
      html5QrCode = new Html5Qrcode(readerId);
      setShowQRImage(false);
      setScanInProgress(true);

      html5QrCode.start(
        { facingMode: "environment" },
        qrConfig,
        (text: string) => {
          setScanLabel("Scan New");
          setShowQRImage(false);
          setScannedText(text);
          html5QrCode.stop();
          dispatch(getAssetByStockNumber({ stockNumber: text }));
          setScanInProgress(false);
        },
        (error: string) => {
          // console.error("Error while scanning QR code:", error);
        }
      );
    }
  };

  const stopQRCode = () => {
    //Check if Scanner Running
    if (html5QrCode && html5QrCode.getState() !== 1) {
      html5QrCode.stop();
    }
    closeCamera();
  };

  const resetInitials = () => {
    setScanInProgress(false);

    setScannedText(null);
    setShowQRImage(true);
    dispatch(resetAssetByStockNumber());
    dispatch(setSelectedAssets([]));
    dispatch(resetSuccess());
  };

  const closeCamera = () => {
    resetInitials();
    setScanLabel("Scan");
    if (isOpen) toggleModal();
  };

  const handleCreateDispatch = () => {
    closeCamera();
    history.push(APP_ROUTES.CreateOrEditDispatch, {
      assetId: asset?.assetId,
    });
  };

  const handleViewAsset = () => {
    closeCamera();
    history.push(APP_ROUTES.ViewAsset, { id: asset?.assetId });
  };

  const handleDispatchComplete = () => {
    dispatch(
      updateDispatchStatus({
        id: dispatchDetails?.dispatchDetailsId,
        status: DispatchStatus.Completed,
        mode: "popup",
      })
    );
  };

  const menuData: menuObjectType = useMemo(() => {
    let menu = {
      2: {
        icon: <InsertLinkRounded />,
        label: "Go to Asset Record",
        handleClick: () => {
          handleViewAsset();
        },
      },
    };

    if (dispatchDetails?.dispatchStatus !== DispatchStatus.New) {
      menu[1] = {
        icon: <Place />,
        label: "Create Dispatch Event",
        handleClick: () => {
          handleCreateDispatch();
        },
      };
    }

    if (
      dispatchDetails &&
      dispatchDetails?.dispatchStatus === DispatchStatus.New
    )
      menu[3] = {
        icon: <CheckSharp />,
        label: "Dispatch Complete",
        handleClick: () => {
          handleDispatchComplete();
        },
      };
    return menu;
  }, [asset, dispatchDetails]);

  return {
    isOpen,
    isLoading,
    statusLoading,
    scanLabel,
    toggleModal,
    scanToUpdateBtn,
    showQRImage,
    startQRCode,
    stopQRCode,
    scannedText,
    asset,
    dispatchDetails,
    menuData,
    statusSuccessMessage,
    scanInProgress,
  };
};

export default useScanToUpdate;
