import { SxProps } from "@mui/material";
import MButton, { ButtonProps } from "@mui/material/Button";
import { containedButtonBlue } from "appConstants/styles";

type ButtonPropType = {
  combinedSx?: SxProps; // INFO: combinedSx is added  here to adjust code which is adding sx to MButton two times
} & Partial<ButtonProps>;

const Button = (props: ButtonPropType) => {
  const { combinedSx, color, children, ...otherProps } = props;

  return (
    <MButton
      variant="contained"
      sx={{
        // INFO: First sx is added here
        ...containedButtonBlue,
        ...((!color || color === "primary") &&
          containedButtonBlue["&.MuiButtonBase-root"].primary),
        ...combinedSx, // INFO: If want to add some new properties along with already add sx (overwrite)
      }}
      {...otherProps} // INFO: If want to add sx overriding the first one otherProps has it
    >
      {children}
    </MButton>
  );
};

export default Button;
