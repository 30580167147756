import { Grid, CircularProgress } from "@mui/material";
import { gutterSpace } from "appConstants/styles";

const Loader = () => {
  return (
    <Grid container spacing={gutterSpace}>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        xs={12}
        item
        sx={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Loader;
