import { Box, Checkbox, Stack, Tooltip, Typography } from "@mui/material";
import { daysSelectorStyle, pxToRem } from "appConstants/styles";
import useDaySelector from "./useDaySelector";

export type DaySelectorPropType = {
  checkedDays?: string;
  onSelect?: (selectedValue: string) => void;
};

const DaySelector = ({ checkedDays = "", onSelect }: DaySelectorPropType) => {
  const { days, selectedDay, handleDayClick } = useDaySelector(
    checkedDays ? checkedDays?.split(",") : [],
    onSelect
  );

  return (
    <Stack direction={"row"} columnGap={pxToRem(7)} sx={daysSelectorStyle}>
      {days.map((item, index) => (
        <Tooltip title={item.value}>
          <Box
            key={index}
            sx={{
              ...daysSelectorStyle.dayWrapper,
              ...(selectedDay.includes(item.value) === true &&
                daysSelectorStyle.dayWrapper.selected),
            }}
          >
            <Typography>{item?.label}</Typography>
            <Checkbox
              inputProps={{
                "aria-label": item.value,
              }}
              value={item.value}
              onChange={handleDayClick}
              checked={selectedDay.includes(item.value) ? true : false}
            />
          </Box>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default DaySelector;
