import { ExpandLessOutlined } from "@mui/icons-material";
import { Typography, Icon, Stack, SxProps } from "@mui/material";
import { Button } from "components";
import { pxToRem } from "appConstants/styles";

interface ExpandAllButtonPropTypes {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  expanded: boolean;
  btnSx?: SxProps;
}

const ExpandAllButton = (props: ExpandAllButtonPropTypes) => {
  const { onClick, expanded, btnSx } = props;
  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={{ width: "auto", "&:hover": { backgroundColor: "unset" }, ...btnSx }}
    >
      <Stack alignItems={"center"} flexDirection={"row"}>
        <Typography variant="caption" fontWeight={500} color={"grey.50"}>
          {expanded ? "Collapse All" : "Expand All"}
        </Typography>
        <Icon
          sx={{
            backgroundColor: "grey.50",
            width: pxToRem(20),
            height: pxToRem(20),
            color: "grey.A100",
            marginLeft: pxToRem(10),
            display: "grid",
            placeItems: "center",
            borderRadius: "100%",

            "&:hover": {
              backgroundColor: "grey.300",
            },
          }}
        >
          <ExpandLessOutlined
            sx={{
              fontSize: pxToRem(16),
              transform: `rotate(${expanded ? 0 : 180}deg)`,
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </Icon>
      </Stack>
    </Button>
  );
};

export default ExpandAllButton;
