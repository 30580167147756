import { Chip, Tooltip } from "@mui/material";
import { customChipStyle } from "appConstants/styles";

interface Props {
  label?: string;
  colors?: string;
  sx?: object;
}

const CustomChip = ({ label = "Sample Chip Label", colors, sx }: Props) => {
  const colorStyle = colors?.split(", ");

  return (
    <Tooltip title={label}>
      <Chip
        label={label}
        sx={{
          backgroundColor: colorStyle?.[0],
          color: colorStyle?.[1],
          ...customChipStyle,
          ...sx,
        }}
      />
    </Tooltip>
  );
};

export default CustomChip;
