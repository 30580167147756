import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from "@mui/material";
import { dialogStyle, gutterSpace, pxToRem } from "appConstants/styles";
import { ReactNode } from "react";
import theme from "utils/theme";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "components/Button";
import useAlertDialogBox from "./useAlertDialogBox";
import ReactMarkdown from "react-markdown";

interface DialogPropsType {
  children?: ReactNode;
  open?: boolean;
  onClose?: (boolean) => void;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  size?: false | Breakpoint;
  dialogTitle: string;
  dialogDescription: string;
  dialogHeading: string;
}

const DialogHeading = styled(DialogTitle)`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.497px;
  color: ${theme.palette.error["400"]};
`;

const AlertDialogBox = (props: DialogPropsType) => {
  const {
    open,
    handleClose,
    size,
    onSubmit,
    dialogTitle,
    dialogHeading,
    dialogDescription,
    onClose,
    cancel,
    continueBtn,
  } = useAlertDialogBox(props);

  return (
    <Dialog open={open} onClose={handleClose} sx={dialogStyle} maxWidth={size}>
      <DialogHeading>
        <WarningIcon />
        {dialogTitle}
      </DialogHeading>
      <DialogContent>
        <Typography
          fontSize={pxToRem(19)}
          fontWeight={600}
          mb={gutterSpace}
          lineHeight={"normal"}
          letterSpacing={pxToRem(0.269)}
        >
          {dialogHeading}
        </Typography>
        <Typography>
          <ReactMarkdown>{dialogDescription}</ReactMarkdown>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancel}</Button>
        <Button onClick={onSubmit}>{continueBtn}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogBox;
