import { Box, Card, TextField, Typography } from "@mui/material";
import { timeClockCardAtomStyle } from "appConstants/styles";
import Button from "components/Button";
import { AccessTime, Check } from "@mui/icons-material";

interface Props {
  type?: "in" | "out";
  value?: string;
  isDisabled?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const TimeClockCardAtom = (props: Props) => {
  const { type, value = "00:00 AM", isDisabled = false, handleClick } = props;

  let cardTitle = "";

  switch (type) {
    case "in":
      cardTitle = "In Time";
      break;
    case "out":
      cardTitle = "Out Time";
      break;
    default:
      cardTitle = null;
  }

  return (
    <Card sx={timeClockCardAtomStyle.cardStyle}>
      <Box>
        <Typography
          component={"span"}
          sx={timeClockCardAtomStyle.cardStyle.heading}
        >
          {cardTitle}
        </Typography>
        <TextField
          sx={timeClockCardAtomStyle.cardStyle.input}
          hiddenLabel
          value={value}
          disabled={isDisabled}
        />
      </Box>
      <Box>
        <Button
          disabled={isDisabled}
          onClick={handleClick}
          sx={timeClockCardAtomStyle.cardStyle.button}
        >
          {!isDisabled ? <AccessTime /> : <Check />}
        </Button>
      </Box>
    </Card>
  );
};

export default TimeClockCardAtom;
