const DISPATCH_FORM_FIELDS = {
  DISPATCH_TYPE_ID: "dispatchTypeId",
  PROFIT_CENTER_ID: "profitCenterId",
  DESTINATION_LOT: "destinationLot",
  DESTINATION_LOT_LOCATION: "destinationLotLocation",
  ESTIMATED_PICKUP_DATE: "estimatedPickUpDate",
  ESTIMATED_DELIVERY_DATE: "estimatedDeliveryDate",
  TRANSPORTER_ID: "transporterId",
};

export default DISPATCH_FORM_FIELDS;
