import { FC } from "react";
import {
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
  TimePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { datePickerStyle } from "appConstants/styles";
import { convertToDayjsDate } from "utils/common";

type TimePickerWrapperPropType = {
  label?: string;
  value?: any;
  onChange: (value: any) => void;
} & Partial<TimePickerProps<any>>;

const TimePickerWrapper: FC<TimePickerWrapperPropType> = (props) => {
  const {
    label = "HH:MM",
    value = convertToDayjsDate(new Date().toISOString()),
    onChange,
    slotProps,
    ...otherProps
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        slotProps={slotProps}
        label={label}
        value={value}
        onChange={onChange}
        sx={{ ...datePickerStyle, width: "100%" }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        {...otherProps}
      />
    </LocalizationProvider>
  );
};

export default TimePickerWrapper;
