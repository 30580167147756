import { MENU_ITEMS_TYPE } from "./types";

export const APP_ROUTES = {
  Home: "/",
  Login: "/login",
  CreateUser: "/createuser",
  ForgotPassword: "/reset-password",
  AuthenticateCode: "/authenticate-code",
  AssignRole: "/roles-access/assign",
  SecurityPersonnel: "/security-personnel",
  CreateRole: "/roles-access/create-role",
  EditRole: "/roles-access/edit-role",
  ViewSecurityPersonnel: "/security-personnel/view-security-personnel",
  EditSecurityPersonnel: "/security-personnel/create-security-personnel",
  ViewRole: "/roles-access/role",
  MergeSecurityPersonnel: "/security-personnel/merge-security-personnel",
  Logout: "/logout",
  ViewRoles: "/roles-access/roles",
  NotFound: "/not-found",
  Inventory: "/inventory",
  ExampleComp: "/components",
  TimeClock: "/timeclock",
  AssetRecords: "/inventory/asset-records",
  DealershipRecords: "/dealership/dealership-records",
  CreateAsset: "/inventory/create-asset",
  EditAsset: "/inventory/edit-asset",
  AccountingOperations: "/inventory/asset/AccountingOperations",
  CreateDealership: "/dealership/create-dealership",
  EditDealership: "/dealership/edit-dealership",
  CreateSubGroupDealership: "/dealership/create-subgroup-dealership",
  ViewDealership: "/dealership/view-dealership",
  DealershipSettingGeneralInfo: "/dealership-setting/general-info",
  ViewAsset: "/inventory/view-asset",
  DuplicateAsset: "/inventory/duplicate-asset",
  ViewAssetHistory: "/inventory/view-asset-history",
  ViewAssetHistoryDetails: "/inventory/view-asset-history-details",
  TransactionListing: "/inventory/transaction-listing",
  DispatchRecords: "/inventory/dispatch-records",
  CreateOrEditDispatch: "/inventory/create-or-edit-dispatch",
  EditDispatchEvent: "/inventory/edit-dispatch-event",
  CancelDispatch: "/inventory/cacel-dispatch",
  ViewDispatch: "/inventory/view-dispatch",
  ViewDispatchHistory: "/inventory/view-dispatch-history",
  ViewDispatchCalendar: "/inventory/view-dispatch-calendar",
  PurchasingListing: "/inventory/purchasing",
  ViewTemplateItem: "/inventory/purchasing/viewTemplate",
  ViewPurchaseItem: "/inventory/view-purchase",
  ManageMedia: "/inventory/manage-media",
  CreatePurchaseOrder: "/inventory/create-purchase",
  EditPurchaseOrder: "/inventory/edit-purchase",
  FinanceQuotes: "/finance/quotes",
  FinanceCreateQuote: "/finance/quotes/create",
  FinanceEditQuote: "/finance/quotes/edit",
  FinanceViewQuote: "/finance/quotes/view",
  FinanceDeals: "/finance/deals",
  FinanceDeliveries: "/finance/deliveries",
};

export const MENU_ITEMS: Array<MENU_ITEMS_TYPE> = [
  {
    index: 1,
    label: "Dealership",
    link: APP_ROUTES.DealershipRecords,
    childLInks: [
      { label: "Dealership", link: APP_ROUTES.DealershipRecords },
      { label: "Sub Group", link: APP_ROUTES.CreateSubGroupDealership },
      {
        link: APP_ROUTES.CreateDealership,
      },
      {
        link: APP_ROUTES.EditDealership,
      },
      {
        link: APP_ROUTES.ViewDealership,
      },
      {
        label: "Dealership Setting",
        link: APP_ROUTES.DealershipSettingGeneralInfo,
      },
    ],
  },
  {
    index: 2,
    label: "Inventory",
    link: APP_ROUTES.AssetRecords,
    childLInks: [
      { label: "Asset Records", link: APP_ROUTES.AssetRecords },
      { link: APP_ROUTES.CreateAsset },
      {
        link: APP_ROUTES.ViewPurchaseItem,
      },
      {
        link: APP_ROUTES.ViewTemplateItem,
      },
      { label: "Purchasing", link: APP_ROUTES.PurchasingListing },
      { label: "Dispatch", link: APP_ROUTES.DispatchRecords },
      {
        link: APP_ROUTES.ViewDispatch,
      },
      {
        link: APP_ROUTES.EditAsset,
      },
      {
        link: APP_ROUTES.ViewAsset,
      },
      {
        link: APP_ROUTES.DuplicateAsset,
      },
      {
        link: APP_ROUTES.ViewAssetHistory,
      },
      {
        link: APP_ROUTES.ViewAssetHistoryDetails,
      },
      { link: APP_ROUTES.TransactionListing },
      {
        link: APP_ROUTES.CreateOrEditDispatch,
      },
      {
        link: APP_ROUTES.ViewDispatchHistory,
      },
      { link: APP_ROUTES.ViewDispatchCalendar },
      { link: APP_ROUTES.ManageMedia },
      { link: APP_ROUTES.CreatePurchaseOrder },
      { link: APP_ROUTES.EditPurchaseOrder },
      { link: APP_ROUTES.AccountingOperations },
    ],
  },
  {
    index: 3,
    label: "Finance",
    link: APP_ROUTES.FinanceQuotes,
    childLInks: [
      { label: "Quote", link: APP_ROUTES.FinanceQuotes },
      {
        link: APP_ROUTES.FinanceCreateQuote,
      },
      {
        link: APP_ROUTES.FinanceEditQuote,
      },
      {
        link: APP_ROUTES.FinanceViewQuote,
      },
      { label: "Deal", link: APP_ROUTES.FinanceDeals },
      { label: "Delivery", link: APP_ROUTES.FinanceDeliveries },
    ],
  },
  {
    index: 4,
    label: "Personnel",
    link: APP_ROUTES.SecurityPersonnel,
    childLInks: [
      { label: "Personnel", link: APP_ROUTES.SecurityPersonnel },
      {
        label: "Roles & Access",
        link: APP_ROUTES.ViewRoles,
      },
      {
        link: APP_ROUTES.EditSecurityPersonnel,
      },
      {
        link: APP_ROUTES.ViewSecurityPersonnel,
      },
      {
        link: APP_ROUTES.CreateRole,
      },
      {
        link: APP_ROUTES.EditRole,
      },
      {
        link: APP_ROUTES.AssignRole,
      },
      {
        link: APP_ROUTES.ViewRole,
      },
      {
        link: APP_ROUTES.MergeSecurityPersonnel,
      },
    ],
  },
  {
    index: 5,
    label: "Reports",
    link: "/",
    childLInks: [
      { label: "Demo Link", link: "/" },
      { label: "Demo Link", link: "/" },
    ],
  },
  {
    index: 6,
    label: "Clock",
    link: APP_ROUTES.TimeClock,
    childLInks: [],
  },
  {
    index: 7,
    label: "components",
    link: APP_ROUTES.ExampleComp,
    childLInks: [],
    showItem: false,
  },
];
