import HistoryIcon from "@mui/icons-material/History";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Button, Loader, SimpleDashHeading } from "components";
import { dialogStyle, gutterSpace, pxToRem } from "appConstants/styles";
import { HistoryData } from "types/purchase";
import useTemplateHistoryDialog from "./useTemplateHistoryDialog";
import {
  ControlPointDuplicateRounded,
  TripOriginRounded,
} from "@mui/icons-material";

export type IHistoryDialogPropType = {
  onClose: () => void;
  isLoading: boolean;
  data: HistoryData;
};

const HistoryDialog = (props: IHistoryDialogPropType) => {
  const {
    onClose,
    isLoading,
    data: { history, info },
  } = props;

  const {
    viewHistory,
    activity,
    duplicatedAndModified,
    updatedBy,
    memo,
    created,
    createdBy,
    origin,
    close,
  } = useTemplateHistoryDialog();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DialogTitle>
        <HistoryIcon />
        <Typography
          sx={dialogStyle["&.MuiDialog-root"][".MuiPaper-root"].mainHeading}
        >
          {viewHistory}
        </Typography>
        <Typography fontSize={pxToRem(24)} lineHeight={pxToRem(32)}>
          {String(info?.name)?.toUpperCase()}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <SimpleDashHeading title={activity} />
        {history?.map((item) => (
          <Grid
            container
            alignItems="flex-start"
            columnSpacing={gutterSpace}
            key={item.updatedOn}
          >
            <Grid item>
              <ControlPointDuplicateRounded />
            </Grid>
            <Grid item flex={1}>
              <Typography fontWeight={600}>{duplicatedAndModified}</Typography>
              <Typography>{item.updatedOn}</Typography>
              <Typography>
                {updatedBy}: {item.updatedBy}
              </Typography>
              <Typography>
                {memo}: {item.changesList?.join(", ")}
              </Typography>
            </Grid>
          </Grid>
        ))}

        <Grid container alignItems="flex-start" columnSpacing={gutterSpace}>
          <Grid item>
            <TripOriginRounded sx={{ color: "secondary.main" }} />
          </Grid>
          <Grid item flex={1}>
            <Typography fontWeight={600}>{created}</Typography>
            <Typography>{info?.createdOn}</Typography>
            <Typography>
              {createdBy}: {info?.createdBy}
            </Typography>
            <Typography>
              {origin}: {info?.name}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{close}</Button>
      </DialogActions>
    </>
  );
};

export default HistoryDialog;
