import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_BUYERS, GET_QUOTES_URL, GET_QUOTE_BY_ID_URL } from "utils/urls";
import axiosClient from "utils/axiosClient";
import { ERROR_MESSAGES } from "../../../appConstants";
import AppError from "utils/AppError";
import { generateQueryParams } from "utils/common";
import { GetQuoteByIDPayload, GetQuotesPayload } from "types/finance";

export const getQuotes = createAsyncThunk(
  GET_QUOTES_URL,
  async (payload: GetQuotesPayload) => {
    try {
      const queryParams = generateQueryParams({
        page: payload.page,
        search: payload.search,
        filter: payload.filter,
      });

      // TODO: Once BE API is finalized
      // const response = await axiosClient.get(
      //   `${GET_QUOTES_URL}?${queryParams}`
      // );

      // TODO: Dummy Data For Quotes
      const dummyData = [
        {
          id: 1,
          status: "Ordered",
          quoteNo: 2324,
          dealType: "FINANCE",
          customerInfo: "Cris Martell ",
          stockNo: "CN43227-2RZ",
          assetInfo: "New 2023 Forest River Sunseeker",
          date: "10/23/23",
        },
        {
          id: 2,
          status: "Ordered",
          quoteNo: 9864,
          dealType: "LEASE",
          customerInfo: "Alexandria Harrington-Montgomery ",
          stockNo: "40VB93727-234X",
          assetInfo: "2023 Gulf Stream Kingsport Super Lite 199D",
          date: "10/23/23",
        },
        {
          id: 3,
          status: "Ordered",
          quoteNo: 4324,
          dealType: "FINANCE",
          customerInfo: "Jerry Mason",
          stockNo: "GD4JDY727-234D",
          assetInfo: "2024 Forest River RV Salem FSX 174BHLE",
          date: "10/23/23",
        },
      ];

      return {
        records: dummyData, //response.data?.records,
        totalRecords: 3, // response.data.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getQuotes,
        error.response.data?.message
      );
    }
  }
);

export const getQuoteById = createAsyncThunk(
  GET_QUOTE_BY_ID_URL,
  async (payload: GetQuoteByIDPayload) => {
    try {
      const response = await axiosClient.get(GET_QUOTE_BY_ID_URL, {
        params: payload,
      });
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getQuotes,
        error.response.data?.message
      );
    }
  }
);

export const getBuyers = createAsyncThunk(GET_BUYERS, async () => {
  try {
    const response = await axiosClient.get(GET_BUYERS);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getBuyers, error.response.data?.message);
  }
});
