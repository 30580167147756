import { createAsyncThunk } from "@reduxjs/toolkit";
import { ERROR_MESSAGES } from "../../../appConstants";
import { AuthentcateCodePayload } from "types/auth";
import AppError from "utils/AppError";
import axiosClient from "utils/axiosClient";
import { RESET_PASSWORD_CODE_URL } from "utils/urls";

export const authenticateCode = createAsyncThunk(
  RESET_PASSWORD_CODE_URL,
  async (payload: AuthentcateCodePayload) => {
    try {
      const response = await axiosClient.post(RESET_PASSWORD_CODE_URL, payload);
      const { data } = response;
      return !!data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.authenticateCode,
        error.response.data?.message
      );
    }
  }
);
