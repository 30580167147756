import FORM_FIELDS from "./formFields";

export enum CREATE_ASSET_SUMMARY_MODE {
  CREATE = "create-asset",
  DUPLICATE = "duplicate-asset",
}

export enum ASSET_IMAGE_SELECTION_MODE {
  CREATE = "create",
  SUMMARY = "summary",
}

export const ASSET_STOCK_NUMBER_LENGTH = 6;

export enum ASSET_UNIT_TYPE {
  ATV = "ATV",
  AUTO = "AUTO",
  BOAT = "BOAT",
  CT_EXP = "CT/EXP",
  FW = "FW",
  M_CYCLE = "M CYCLE",
  MH = "MH",
  TH = "TH",
  TRK_CAMP = "TRK CAMP",
  TT = "TT",
  UT = "UT",
}

export enum ASSET_TYPE {
  MOTORIZED = "Motorized",
  TOWABLE = "Towable",
  FIXED_ASSET = "Fixed Asset",
}

export enum DispatchStatus {
  New = 1,
  Cancel = 2,
  Completed = 3,
}

export enum PurchasingViewMode {
  TEMPLATES = "templates",
  PURCHASE_ORDER = "PurchaseOrder",
}

export const ASSET_DEFAULT_LOCATION = "CORP";

export enum CREATE_ASSET_TAB_IDS {
  UNIT_INFO_PRICING = "unitInfo&Pricing",
  OTHER_PRICING = "otherPricing",
  METADATA_DESCRIPTION = "metadata&Description",
  LOCATION = "location",
}
