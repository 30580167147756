import { Typography, Stack, SvgIcon } from "@mui/material";
import { styled } from "@mui/system";
import { sidebar__link } from "appConstants/styles";
import { NavLink } from "react-router-dom";
import useSidebarLink from "./useSidebarLink";

const CustomLink = styled(NavLink)`
  text-decoration: none;
  text-align: center;
`;

const SidebarLink = ({ item, handleSideBarChange, index }) => {
  const itemIsActive = useSidebarLink(item);

  return (
    <CustomLink
      to={item?.link}
      onClick={() => handleSideBarChange(index)}
      exact
      sx={sidebar__link}
      className={`${"section-" + item?.label.toLowerCase()} ${
        itemIsActive && "isActive"
      }`}
    >
      {item?.showItem !== false && (
        <>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: "50%",
              backgroundColor: "greyVariantAlt.600",
              width: 49,
              height: 49,
            }}
          >
            <SvgIcon className="icon">
              {
                // dealership
                item?.label === "Dealership" ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.68695 7.99999H0L6.25706 12.41L3.88404 20L10.1411 15.31L16.4083 20L14.0251 12.41L20.2822 7.99999H12.5952L10.1411 0L7.68695 7.99999ZM20.6473 20L18.7611 13.99L23 11H19.5115L16.388 13.2L17.8687 17.9199L20.6473 20ZM14.3801 5.40665e-06L16.2257 6H14.1062L13.3254 3.45L14.3801 5.40665e-06Z"
                    className="svg-path"
                  />
                ) : // inventory
                item?.label === "Inventory" ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.888 13.3895V22.5212H21.015V13.3895H11.888ZM0.479248 22.5212H9.60622V13.3895H0.479248V22.5212ZM0.479248 1.97481V11.1065H9.60622V1.97481H0.479248ZM16.0636 0.479492L9.60622 6.92877L16.0636 13.3895L22.5209 6.92877L16.0636 0.479492Z"
                    className="svg-path"
                  />
                ) : // inventory
                item?.label === "Finance" ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.55556 0H20.4444C21.85 0 23 1.15 23 2.55556V20.4444C23 21.85 21.85 23 20.4444 23H2.55556C1.15 23 0 21.85 0 20.4444V2.55556C0 1.15 1.15 0 2.55556 0ZM14.1706 3.83333L12.8161 5.18777L14.6177 7.00223L12.8161 8.80389L14.1706 10.1583L15.9723 8.35667L17.7739 10.1456L19.1283 8.79111L17.3267 6.98944L19.1283 5.18777L17.7739 3.83333L15.9723 5.635L14.1706 3.83333ZM4.15304 6.03107H10.542V7.94774H4.15304V6.03107ZM8.30556 16.6111H10.8611V14.6944H8.30556V12.1388H6.38889V14.6944H3.83333V16.6111H6.38889V19.1667H8.30556V16.6111ZM19.167 18.2083H12.778V16.2917H19.167V18.2083ZM12.778 15.0138H19.167V13.0972H12.778V15.0138Z"
                    className="svg-path"
                  />
                ) : //persoonel
                item?.label === "Personnel" ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 2.75H20.625V0H17.875V2.75H6.875V0H4.125V2.75H2.75C1.22375 2.75 0 3.9875 0 5.5V24.75C0 26.2625 1.22375 27.5 2.75 27.5H22C23.5125 27.5 24.75 26.2625 24.75 24.75V5.5C24.75 3.9875 23.5125 2.75 22 2.75ZM12.375 6.875C14.6575 6.875 16.5 8.7175 16.5 11C16.5 13.2825 14.6575 15.125 12.375 15.125C10.0925 15.125 8.25 13.2825 8.25 11C8.25 8.7175 10.0925 6.875 12.375 6.875ZM20.625 23.375H4.125V22C4.125 19.25 9.625 17.7375 12.375 17.7375C15.125 17.7375 20.625 19.25 20.625 22V23.375Z"
                    className="svg-path"
                  />
                ) : //report
                item?.label === "Reports" ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.55556 0H20.4444C21.85 0 23 1.15 23 2.55556V20.4444C23 21.85 21.85 23 20.4444 23H2.55556C1.15 23 0 21.85 0 20.4444V2.55556C0 1.15 1.15 0 2.55556 0ZM5.11108 17.8889H7.66663V8.94445H5.11108V17.8889ZM12.7778 17.8889H10.2223V5.11112H12.7778V17.8889ZM15.3333 17.8889H17.8889V12.7777H15.3333V17.8889Z"
                    className="svg-path"
                  />
                ) : //notification
                item?.label === "Clock" ? (
                  <path
                    d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9963 8.81853 22.7309 5.76842 20.4812 3.51878C18.2316 1.26914 15.1815 0.00367709 12 0ZM18.4615 12.9231H12C11.8788 12.9231 11.7587 12.8993 11.6467 12.853C11.5347 12.8066 11.4329 12.7386 11.3471 12.6529C11.2614 12.5671 11.1934 12.4654 11.147 12.3533C11.1007 12.2413 11.0769 12.1212 11.0769 12V5.53846C11.0769 5.29365 11.1742 5.05886 11.3473 4.88575C11.5204 4.71264 11.7552 4.61538 12 4.61538C12.2448 4.61538 12.4796 4.71264 12.6527 4.88575C12.8258 5.05886 12.9231 5.29365 12.9231 5.53846V11.0769H18.4615C18.7064 11.0769 18.9411 11.1742 19.1143 11.3473C19.2874 11.5204 19.3846 11.7552 19.3846 12C19.3846 12.2448 19.2874 12.4796 19.1143 12.6527C18.9411 12.8258 18.7064 12.9231 18.4615 12.9231Z"
                    className="svg-path"
                  />
                ) : (
                  //default
                  false
                )
              }
            </SvgIcon>
          </Stack>
          <Typography
            component="div"
            sx={{
              color: "grey.100",
              fontSize: "10px",
              fontWeight: "300",
              letterSpacing: "0.2px",
              textDecoration: "none",
            }}
          >
            {item?.label}
          </Typography>
        </>
      )}
    </CustomLink>
  );
};

export default SidebarLink;
