import { Divider, Grid, SxProps } from "@mui/material";
import { gutterSpace } from "appConstants/styles";
import { FC } from "react";

type HorizontalDividerPropType = {
  sx?: SxProps;
};

const HorizontalDivider: FC<HorizontalDividerPropType> = (props) => {
  const { sx } = props;

  return (
    <Grid
      item
      xs={12}
      sx={{
        marginTop: gutterSpace,
        ...sx,
      }}
    >
      <Divider />
    </Grid>
  );
};

export default HorizontalDivider;
