const PERSONNEL_FORM_FIELDS = {
  LOGIN: "login",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL: "email",
  CELL_PHONE: "cellPhone",
  PROFILE_PICTURE: "profilePicture",
  IS_ACTIVE: "isActive",
  PREFERRED_CONTACT: "preferredContact",
  STREET_ADDRESS: "streetAddress",
  STREET_ADDRESS_2: "streetAddress2",
  APPT_NUMBER: "apptNumber",
  POSTAL_CODE: "postalCode",
  DOB: "dob",
  DRIVER_LICENCE: "driverLicence",
  STATE_ISSUED: "stateIssued",
  GENDER: "gender",
  SSN: "ssn",
  HIRE_DATE: "hireDate",
  EMPLOYEE_ID: "employeeId",
  EMPLOYEE_INBOX: "employeeInbox",
  SECURITY_BADGE: "securityBadge",
  MANAGER_ID: "managerId",
  DEPARTMENT_ID: "departmentId",
  COUNTRY_ID: "countryId",
  STATE_ID: "stateId",
  COUNTY_ID: "countyId",
  CITY: "city",
  SHIFT_TIME: "shiftTime",
  IS_MANAGER: "isManager",
  IS_SSO: "isSSO",
  IS_NOTIFY_USER: "isNotifyUser",
  ROLES: "roles",
  TENANT_ID: "tenantId",
};

export default PERSONNEL_FORM_FIELDS;
