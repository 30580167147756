import { Box, Stack, Typography } from "@mui/material";
import AlternateEmailSharp from "@mui/icons-material/AlternateEmailSharp";
import CheckCircleSharp from "@mui/icons-material/CheckCircleSharp";
import DisabledByDefaultSharp from "@mui/icons-material/DisabledByDefaultSharp";
import LocalPhoneSharp from "@mui/icons-material/LocalPhoneSharp";
import TripOriginRounded from "@mui/icons-material/TripOriginRounded";
import { contactCardInfoStyle, pxToRem } from "appConstants/styles";
import { IconDropdown } from "components";
import { menuObjectType } from "appConstants/types";

type ContactCardInfoPropType = {
  isShowBuyerBadge?: boolean;
  isShowCoBuyerBadge?: boolean;
  title?: string;
  idNmumber?: number;
  phoneNumber?: string;
  email?: string;
  amount?: number;
  buyerCountCurrent?: number;
  buyerCountTotal?: number;
  isVerified?: boolean;
  menuData?: menuObjectType;
  children?: React.ReactNode;
  sx?: object;
};

const ContactCardInfo = (props: ContactCardInfoPropType) => {
  const {
    isShowBuyerBadge = false,
    isShowCoBuyerBadge = false,
    title = "Card Title",
    idNmumber,
    phoneNumber,
    email,
    amount,
    buyerCountCurrent,
    buyerCountTotal,
    isVerified = false,
    children,
    menuData,
    sx,
  } = props;

  return (
    <Stack direction={"row"} sx={{ ...contactCardInfoStyle, ...sx }}>
      <Box sx={contactCardInfoStyle.contentWrapper}>
        {children || (
          <>
            <Stack direction={"row"} alignItems={"center"}>
              {(isShowBuyerBadge || isShowCoBuyerBadge) && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  width={pxToRem(85)}
                >
                  <TripOriginRounded sx={contactCardInfoStyle.subTitleIcon} />
                  <Typography variant="h4" sx={contactCardInfoStyle.subTitle}>
                    {isShowBuyerBadge && "Buyer"}
                    {isShowCoBuyerBadge && "Co-Buyer"}
                  </Typography>
                </Stack>
              )}
              {idNmumber && (
                <Box>
                  <Typography
                    color={"secondary.main"}
                    fontSize={pxToRem(12)}
                    fontWeight={500}
                  >
                    {idNmumber}
                  </Typography>
                </Box>
              )}
              {menuData && (
                <Box sx={{ flex: 1, textAlign: "right", mr: pxToRem(-5) }}>
                  <IconDropdown
                    menuData={menuData}
                    buttonProps={{ sx: { padding: pxToRem(3) } }}
                  />
                </Box>
              )}
            </Stack>
            <Typography variant="h4" sx={contactCardInfoStyle.titleStyle}>
              {title}
            </Typography>
            <Stack direction={"row"} justifyContent={"space-between"}>
              {phoneNumber && (
                <Stack sx={contactCardInfoStyle.listStyle}>
                  <LocalPhoneSharp sx={contactCardInfoStyle.listStyle.icon} />
                  <Typography sx={contactCardInfoStyle.listStyle.paragraphy}>
                    {phoneNumber}
                  </Typography>
                </Stack>
              )}
              {amount && (
                <Typography
                  color={"secondary.main"}
                  sx={contactCardInfoStyle.listStyle.paragraphy}
                >
                  ${amount}
                </Typography>
              )}
            </Stack>

            <Stack sx={contactCardInfoStyle.listStyle}>
              {email && (
                <Stack sx={contactCardInfoStyle.listStyle}>
                  <AlternateEmailSharp
                    sx={contactCardInfoStyle.listStyle.icon}
                  />
                  <Typography sx={contactCardInfoStyle.listStyle.paragraphy}>
                    {email}
                  </Typography>
                </Stack>
              )}
              {!!buyerCountCurrent && !!buyerCountTotal && (
                <Typography
                  sx={contactCardInfoStyle.listStyle.paragraphy}
                  display={"flex"}
                  alignItems={"center"}
                >
                  {buyerCountCurrent}/{buyerCountTotal}{" "}
                  <Box
                    sx={{
                      ...contactCardInfoStyle.verifiedBadge,
                      ...(isVerified
                        ? contactCardInfoStyle.verifiedBadge.verified
                        : contactCardInfoStyle.verifiedBadge.unVerified),
                    }}
                  >
                    {isVerified ? (
                      <CheckCircleSharp sx={{ fontSize: pxToRem(14) }} />
                    ) : (
                      <DisabledByDefaultSharp sx={{ fontSize: pxToRem(14) }} />
                    )}
                  </Box>
                </Typography>
              )}
            </Stack>
          </>
        )}
      </Box>
    </Stack>
  );
};

export default ContactCardInfo;
