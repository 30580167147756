import { styled } from "@mui/material";
import HtmlToReact from "html-to-react";
import {
  DATE_TIME_FORMAT,
  DAY,
  DISPATCH_TYPES,
  DISPLAY_MODE,
  ONLY_CONVERT_UPPERCASE_WORDS,
} from "appConstants";
import { APP_ROUTES } from "appConstants/routes";
import dayjs from "dayjs";
import { Asset, ProfitCenter } from "types/inventory";
import { Dispatch } from "types/dispatch";
import { getGridStringOperators } from "@mui/x-data-grid-premium";
import { DealershipLotLocation } from "types/dealership";

export const formatWeekDays = (days: number) => {
  const weeks = Math.floor(days / 7);
  const days_out = days % 7;
  return (
    (weeks > 0 ? weeks + " week" + (weeks > 1 ? "s, " : ", ") : "") +
    (days_out > 0 ? days_out + " day" + (days_out > 1 ? "s" : "") : "")
  );
};

export const checkNull = (value: string | number | null): string => {
  return value === null || value === undefined || !String(value).trim()
    ? "N/A"
    : `${value}`;
};
export const checkNullPrice = (
  value: string | number | null,
  locales = "en-US",
  currency = "USD"
): string => {
  return value === null || value === undefined
    ? "N/A"
    : formatPrice(value, locales, currency);
};

export const formatPrice = (
  value: string | number | null,
  locales = "en-US",
  currency = "USD"
) => {
  const currencyFormating = new Intl.NumberFormat(locales, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: value === 0 ? 2 : 0,
  });

  return currencyFormating.format(
    Number(value?.toString()?.replaceAll(",", ""))
  );
};

export const getQueryParam = (search: string, param: string) => {
  const queryParams = new URLSearchParams(search);
  return decodeURIComponent(queryParams.get(param) || "");
};

// INFO: For multiple params
export const getQueryParams = (search: string, params: string[]) => {
  return params.map((param) => getQueryParam(search, param));
};

//ToDo: Dropzone -> FileDropzone, Security -> BulkImportDialog, UserImageUpload -> CreateUserImage
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const formatTime = (timestamp: Date) => {
  const date = dayjs(timestamp);
  const now = dayjs();

  if (date.isSame(now, "day")) {
    return "Today, " + date.format(DATE_TIME_FORMAT.time.HMA);
  } else {
    return date.format(DATE_TIME_FORMAT.date.D_MMM_YYYY_HMA_FORMAT);
  }
};

export const formatDate = (date: Date | string, format?: string) => {
  return dayjs(date).format(format || DATE_TIME_FORMAT.date.MM_DD_YYYY_FORMAT);
};

export const addHoursToDate = (dateString, hoursToAdd) => {
  var date = new Date(dateString);
  date.setHours(date.getHours() + hoursToAdd);
  return date.toISOString().split("T")[0];
};

export const processPersonnel = (selectedPersonnels, asset) => {
  let personnel = null;
  if (selectedPersonnels.length > 0) {
    selectedPersonnels.forEach((obj) => {
      const key = `assignedTo_${asset.assetId}`;
      if (key in obj) {
        personnel = obj[key];
      }
    });
  }
  return personnel;
};

export const getDispatchLabel = (dispatchType: number) => {
  for (const key in DISPATCH_TYPES) {
    if (DISPATCH_TYPES[key].id === dispatchType) {
      return DISPATCH_TYPES[key].label;
    }
  }
  return null; // or handle the case where the dispatchType is not found
};

export const assetLocation = (asset: Asset, dispatchType: number) => {
  switch (dispatchType) {
    case DISPATCH_TYPES.Internal.id:
      return asset?.unitLocation?.lotLocations?.lotLocationName;
    case DISPATCH_TYPES.Manufacturer.id:
      return asset?.manufacturer?.manufacturerName;
    default:
      return asset?.profitCenter?.dealershipDetails?.profitCenter;
  }
};

export const dispatchSourceLocation = (
  dispatchitem: Dispatch,
  dispatchType: number,
  mode?: string
) => {
  if (dispatchType === DISPATCH_TYPES.Internal.id) {
    if (mode === DISPLAY_MODE.LISTING) {
      return dispatchitem?.sourceLotLocation;
    } else {
      return dispatchitem?.sourceDealershipLotLocation?.lotLocationName;
    }
  }

  if (dispatchType === DISPATCH_TYPES.Manufacturer.id) {
    return dispatchitem?.asset?.manufacturer?.manufacturerName;
  }

  if (mode === DISPLAY_MODE.LISTING) {
    return dispatchitem?.source;
  } else {
    return dispatchitem?.sourceDealership?.dealershipDetails?.profitCenter;
  }
};

export const dispatchDestinationLocation = (
  dispatchitem: Dispatch,
  dispatchType: number,
  mode?: string
) => {
  if (dispatchType === DISPATCH_TYPES.Internal.id) {
    if (mode === DISPLAY_MODE.LISTING) {
      return (
        dispatchitem?.destinationLotLocation ?? dispatchitem?.destinationLot
      );
    } else if (mode === DISPLAY_MODE.VIEW) {
      return dispatchitem?.destinationDealershipLot?.lotName;
    } else {
      return (
        dispatchitem?.destinationDealershipLotLocation?.lotLocationName ??
        dispatchitem?.destinationDealershipLot?.lotName
      );
    }
  }

  if (mode === DISPLAY_MODE.LISTING) {
    return dispatchitem?.destination;
  } else {
    return dispatchitem?.destinationDealership?.dealershipDetails?.profitCenter;
  }
};

export const extractImageURL = (imageStr: string, index: number) => {
  if (imageStr) {
    return imageStr.split(",")?.[index];
  }
  return imageStr;
};

export const addZeroToNumberStart = (num: number) => {
  return ("0" + num).slice(-2);
};

export const generateQueryParams = (params: {
  [key: string]: string | number | boolean;
}) => {
  const queryParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value !== undefined && value !== null && value !== "") {
      queryParams.append(key, String(value));
    }
  });
  return queryParams.toString();
};

export const shippingLink = () => {
  const fullUrl = window.location.href;
  const domainName = new URL(fullUrl).origin;
  const shippingLink = domainName + APP_ROUTES.ViewPurchaseItem + "?id=";
  return shippingLink;
};

export const addSpaceBetweenCapitalString = (label: string) => {
  return label.replace(/([A-Z0-9])/g, " $1").trim();
};

export const capitalizeFirstLetter = (string) => {
  if (string && typeof string === "string") {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const makeFirstLetterLower = (string) => {
  if (string && typeof string === "string") {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
  return string;
};

export const htmlToReactParser = (htmlStr: string) => {
  const htmlToReactParser = HtmlToReact.Parser();

  return htmlToReactParser.parse(htmlStr);
};

export const convertToYesNo = (value: unknown, yes = "Y", no = "N") => {
  return value ? yes : no;
};

export const getYears = (minYear: number) => {
  const years = [];
  const maxYear = new Date().getUTCFullYear() + 1;

  for (let year = maxYear; year > minYear; year--) {
    years.push(year);
  }

  return years;
};

export const getStorageItem = (item: string) => {
  const storageItem = localStorage.getItem(item);
  return storageItem;
};

export async function downloadFileFromResponse(response) {
  const file = new Blob([response.data], {
    type: response.headers["content-type"],
  });

  const fileURL = URL.createObjectURL(file);

  const link = document.createElement("a");
  link.href = fileURL;

  const contentDisposition = response.headers["content-disposition"];
  let filename = "transactions.csv";
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }
  link.setAttribute("download", filename);

  link.style.display = "none";
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(fileURL);
}

export const splitCamelCase = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const splitAndCapatalizeFirstLetter = (str: string) => {
  if (str && typeof str === "string") {
    if (ONLY_CONVERT_UPPERCASE_WORDS[str]) {
      return str.toUpperCase();
    }
    return str
      .replace(/([a-z])([A-Z0-9])/g, "$1 $2")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return str;
};

export const filterOperators = () => {
  return getGridStringOperators().filter(
    (operator) => operator.value === "contains" || operator.value === "equals"
  );
};

export const extractId = (
  values: Array<any>,
  valueLabel: string,
  idField = "id",
  valueField = "value"
) => {
  return values?.find((value) => value[valueField] === valueLabel)?.[idField];
};

export const extractValue = (
  values: Array<any>,
  id: number,
  idField = "id",
  valueField = "value"
) => {
  return values?.find((value) => value[idField] === id)?.[valueField];
};

export const truncateString = (
  str: string,
  maxChar: number,
  endText = "..."
) => {
  return str
    ? `${str.substring(0, maxChar)}${str?.length > maxChar ? endText : ""}`
    : "N/A";
};

export const extractLotLocation = (
  asset: Partial<Asset>,
  lotLocations?: DealershipLotLocation[],
  profitCenters?: ProfitCenter[]
) => {
  if (asset?.lotLocationId || asset?.profitCenterId) {
    return `${
      asset.lotLocation
        ? extractValue(lotLocations, asset.lotLocationId, "id", "lotName")
        : ""
    }${asset.lotLocationId && asset.profitCenterId && ","}${
      asset.profitCenterId
        ? extractValue(
            profitCenters,
            asset.profitCenterId,
            "dealershipId",
            "dealershipName"
          )
        : ""
    }`;
  }

  return `${asset?.unitLocation?.lotLocations?.lotLocationName ?? ""}${
    asset?.unitLocation?.lotLocations?.lotLocationName &&
    asset?.profitCenter?.shortName
      ? ","
      : ""
  }${asset?.profitCenter?.shortName ?? ""}`;
};

export const getPriceRange = (minPrice: number, maxPrice: number) => {
  return minPrice && maxPrice
    ? `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`
    : "N/A";
};

export const convertEnumToObjectArray = (
  enumObj: unknown,
  key1: string = "label",
  key2: string = "value"
) =>
  Object.keys(enumObj)
    .map(
      (x: unknown) =>
        Number.isNaN(+x) && { [key1]: x, [key2]: enumObj[x as any] }
    )
    .filter((val: unknown) => val);

// INFO: Form Nested Inputs Names
export const getIndexedInputName = (name: string, index: number) =>
  `${name}${index}`;

export const getIndexedInputNestedInputName = (
  parentIndex: number,
  name: string,
  index: number
) => `${parentIndex}${name}${index}`;

export const getSelectedOptionLabel = (label: any) =>
  label?.props?.children?.props?.children || "";

export const subtractDates = (date1: Date | string, date2 = Date.now()) => {
  return dayjs(date2).diff(dayjs(date1), "day");
};

export const compileAddress = (
  streetAddress: string,
  city: string,
  county: string,
  state: string,
  country: string
) => {
  return `${streetAddress}
  ${city ? `, ${city}` : ""}
  ${county ? `, ${county}` : ""}
  ${state ? `, ${state}` : ""}
  ${country ? `, ${country}` : ""}`;
};

// INFO: It will stop user to write even user want to write in disabled field
export const stopWriting = { onKeyDown: (e: any) => e.preventDefault() };

export const stopClickPropagation = {
  onClick: (e: any) => {
    e.stopPropagation();
  },
};

export const convertToDayjsDate = (date: string) =>
  dayjs(date).isValid() ? dayjs(date) : null;

export const convertToISOTime = (timeString: string) => {
  if (!timeString) {
    return null;
  }
  const now = dayjs().format(DATE_TIME_FORMAT.date.YYYY_MM_DD_WITH_HYPHENS);
  return dayjs(`${now}T${timeString}`).toISOString();
};

export const setReduxCommonPendingState = (state: any) => {
  state.isLoading = true;
  state.isSuccess = false;
  state.error.title = "";
  state.error.description = "";
  state.successMessage = "";
};

export const compileFullName = (data: any) =>
  `${data?.firstName}${data?.lastName ? ` ${data?.lastName}` : ""}`;

export const days = Object.values(DAY).map((value) => ({
  label: value?.[0],
  value,
}));
