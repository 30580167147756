import { List, ListItem } from "@mui/material";
import SidebarLink from "./SidebarLink";
import { gutterSpace } from "appConstants/styles";

export const SidebarList = ({ menu, handleSideBarChange }) => {
  return (
    <List
      sx={{
        flex: 1,
        margin: `${gutterSpace} auto`,
        padding: 0,
      }}
    >
      {menu.map((item, index) => (
        <ListItem
          key={item.index}
          disablePadding
          sx={{ justifyContent: "center", marginBottom: "13px" }}
        >
          <SidebarLink
            item={item}
            index={index}
            handleSideBarChange={handleSideBarChange}
          />
        </ListItem>
      ))}
    </List>
  );
};
