import { Stack, Box, Typography, Button } from "@mui/material";
import { errorGraphic } from "appConstants/index";
import { APP_ROUTES } from "appConstants/routes";
import { errorMsgStyle, gutterSpace, pxToRem } from "appConstants/styles";
import { useHistory } from "react-router-dom";

type Props = {
  imgSrc?: string;
  errorCode?: string;
  errorMsg?: string;
};

const ErrorCard = (props: Props) => {
  const { imgSrc, errorMsg, errorCode } = props;
  const history = useHistory();

  return (
    <>
      <Stack
        sx={errorMsgStyle}
        spacing={gutterSpace}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box className="grphicHolder">
          <img src={!imgSrc && errorGraphic} alt="error" />
        </Box>
        {errorMsg && (
          <Typography component={"h2"} className="errorMsg">
            {errorCode && <b className="errorCode">Error{errorCode}!</b>}{" "}
            {errorMsg}
          </Typography>
        )}
        <Box>
          <Button
            variant="contained"
            sx={{
              p: pxToRem(12, 40),
              mt: pxToRem(40),
            }}
            onClick={() => history.push(APP_ROUTES.Home)}
          >
            Go To Homepage
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default ErrorCard;
