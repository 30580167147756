import { GridColDef } from "@mui/x-data-grid-premium";
import { pxToRem } from "appConstants/styles";
import { useMemo, useState } from "react";
import { splitAndCapatalizeFirstLetter, filterOperators } from "utils/common";
import { DynamicGridPropType } from ".";
import {
  DynamicGridColumnsForModification,
  DYNAMIC_GRID_COLUMNS_REQUIRE_MODIFICATION,
  DYNAMIC_GRID_FIELDS_TO_HIDE,
} from "appConstants/index";

const useDynamicGrid = (args: DynamicGridPropType) => {
  const { rows, actionColumn, renderCustomCell } = args;

  const [cachedColumns, setCachedColumns] = useState<GridColDef[]>([]);

  const actionMenu = useMemo(
    () =>
      ({
        field: "action",
        width: Number(pxToRem(64)),
        headerClassName: "super-app-theme--header",
        headerAlign: "right",
        align: "right",
        sortable: false,
        resizable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        ...actionColumn,
      } as GridColDef),
    [actionColumn]
  );

  // INFO: Dynamically generate columns based on data keys
  const columns = useMemo(() => {
    if (!rows || rows.length === 0) {
      return cachedColumns;
    }

    const columnsData = Object.keys(rows[0]).reduce((acc, key) => {
      if (DYNAMIC_GRID_FIELDS_TO_HIDE[key]) {
        return acc;
      }
      if (DYNAMIC_GRID_COLUMNS_REQUIRE_MODIFICATION[key]) {
        renderCustomCell(key as DynamicGridColumnsForModification, acc);
      } else {
        acc.push({
          field: key,
          headerName: splitAndCapatalizeFirstLetter(key),
          headerClassName: "super-app-theme--header",
          filterOperators: filterOperators(),
        } as GridColDef);
      }
      return acc;
    }, []);

    if (actionColumn) {
      columnsData.push(actionMenu);
    }

    // INFO: If columns data is not empty, update cached columns
    setCachedColumns(columnsData);

    return columnsData;
  }, [rows]);

  return { columns };
};

export default useDynamicGrid;
