import { useState } from "react";
import {
  Box,
  ButtonProps,
  ClickAwayListener,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
  SxProps,
  Typography,
} from "@mui/material";
import Button from "components/Button";
import { linkButtonGrey50, pxToRem } from "appConstants/styles";
import InfoIcon from "@mui/icons-material/Info";

type InfoPopperPropType = {
  buttonTitle?: string;
  wrapperSx?: SxProps;
  buttonStartIcon?: React.ReactNode;
  buttonEndIcon?: React.ReactNode;
  buttonProps?: ButtonProps;
} & Partial<PopperProps>;

const InfoPopper: React.FC<InfoPopperPropType> = (props) => {
  const {
    buttonTitle = "Info Popper",
    wrapperSx,
    placement = "bottom-end",
    buttonStartIcon,
    buttonEndIcon,
    buttonProps,
    children,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { sx: buttonSx, ...buttonPropsObj } = buttonProps ?? {};

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const clickAwayHandler = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <Box sx={{ display: "inline-block", ...wrapperSx }}>
      <Button
        startIcon={buttonStartIcon ?? <InfoIcon />}
        endIcon={buttonEndIcon}
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        sx={{
          ...linkButtonGrey50,
          fontSize: pxToRem(12),
          ...(buttonSx ?? {}),
        }}
        {...buttonPropsObj}
      >
        {buttonTitle}
      </Button>
      <ClickAwayListener onClickAway={clickAwayHandler}>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          disablePortal
          sx={{ zIndex: 1 }}
          onBlur={() => setAnchorEl(null)}
        >
          {children || (
            <Paper elevation={3}>
              {" "}
              <Typography variant="body1">
                The content of the Popper.
              </Typography>
            </Paper>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

export default InfoPopper;
