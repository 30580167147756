export enum PERMISSION {
  ACCESS_READ = "AccessRead",
  USER_CREATE = "UserCreate",
  USER_READ = "UserRead",
  USER_UPDATE = "UserUpdate",
  USER_DELETE = "UserDelete",
  USER_SELF_READ = "UserSelfRead",
  USER_SELF_UPDATE = "UserSelfUpdate",
  ROLES_CREATE = "RolesCreate",
  ROLES_READ = "RolesRead",
  ROLES_UPDATE = "RolesUpdate",
  ROLES_DELETE = "RolesDelete",
  ROLES_ASSIGN = "RolesAssign",
  ROLES_REMOVE_ASSIGNMENT = "RolesRemoveAssignment",
  ROLES_READ_PERMISSIONS = "RolesReadPermissions",
  PERMISSIONS_ASSIGN_ROLES = "PermissionsAssignRoles",
  PERMISSIONS_REMOVE_ROLE_ASSIGNMENT = "PermissionsRemoveRoleAssignment",
  PERMISSIONS_ASSIGN_USER = "PermissionsAssignUser",
  PERMISSIONS_REMOVE_USER_ASSIGNMENT = "PermissionsRemoveUserAssignment",
  REPORTING_READ = "ReportingRead",
  REPORTING_ADMIN_READ = "ReportingAdminRead",
  REPORTING_MANAGEMENT_READ = "ReportingManagementRead",
  REPORTING_SELF_READ = "ReportingSelfRead",
  TENANT_CREATE = "TenantCreate",
  TENANT_READ = "TenantRead",
  TENANT_UPDATE = "TenantUpdate",
  TENANT_DELETE = "TenantDelete",
  TENANT_MOVE = "TenantMove",
}
